import React, { useState, useRef, useEffect } from "react";
import { CButton, CCol, CRow, CTooltip } from "@coreui/react";
import axios from "axios";
import { Navbar } from "../components/Navbar";
import "../styles/chat.css";
import "../styles/chroma_sidebar.css";
import { IoLogoBuffer, IoMdImages } from "react-icons/io";

import ImageGenerator from "../components/ImageGenerator";
import OtherImageGallary from "../components/OtherImageGallary";
import { IoArrowBackCircle, IoClose } from "react-icons/io5";
import ImageChat from "../components/ImageChat";
import ImageEditor from "../components/ImageEditor";
import TextToolbar from "../components/TextToolbar";
import { PiTextTBold } from "react-icons/pi";
// import { AiOutlineProduct } from "react-icons/ai";
import { PiSelectionBackgroundDuotone } from "react-icons/pi";
import { GiClothes } from "react-icons/gi";
import { GiSofa } from "react-icons/gi";
import { RiEmojiStickerLine } from "react-icons/ri";
import OverlayImage from "../components/OverlayImage";
import OverlayChat from "../components/OverlayChat";
import { v4 as uuidv4 } from "uuid";
import OverlayImageEditor from "../components/OverlayImageEditor";
import { useNavigate, useParams } from "react-router-dom";

const ChromaChat = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const region = JSON.parse(sessionStorage.getItem("region"));
  const overlayStageRef = useRef();
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const messageContainerRef = useRef(null);
  const [generatedData, setGeneratedData] = useState([]);
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [imageCount, setImageCount] = useState(4);
  const [selectedTab, setSelectedTab] = useState("ImageGenerator");
  const [elements, setElements] = useState({
    background: { imageUrl: "", id: null },
    images: [],
    texts: [],
  });
  const Tabs =
    id === "editor"
      ? [
          ["AI Image Generator", "ImageGenerator", IoMdImages],
          ["Overlay Image", "OverlayImage", IoMdImages],
          ["Backdrop Images", "backdrop_images", PiSelectionBackgroundDuotone],
          ["Fashion", "fashion", GiClothes],
          ["Logos", "logo_images", IoLogoBuffer],
          ["Furniture", "furniture", GiSofa],
          ["Text", "Text", PiTextTBold],
        ]
      : id === "fashion"
      ? [
          ["AI Image Generator", "ImageGenerator", IoMdImages],
          ["Overlay Image", "OverlayImage", IoMdImages],
          ["Backdrop Images", "backdrop_images", PiSelectionBackgroundDuotone],
          ["Fashion", "fashion", GiClothes],
          ["Logos", "logo_images", IoLogoBuffer],
          ["Text", "Text", PiTextTBold],
        ]
      : [
          ["AI Image Generator", "ImageGenerator", IoMdImages],
          ["Overlay Image", "OverlayImage", IoMdImages],
          ["Backdrop Images", "backdrop_images", PiSelectionBackgroundDuotone],
          ["Logos", "logo_images", IoLogoBuffer],
          ["Furniture", "furniture", GiSofa],
          ["Text", "Text", PiTextTBold],
        ];
  const [selectedElement, setSelectedElement] = useState({
    id: "",
    type: "",
    element: null,
  });
  const [overlayFormState, setOverlayFormState] = useState({
    prompt: "",
    numberOfImages: 1,
    selectedImage: null,
  });
  const [resizeState, setResizeState] = useState({
    unit: "pixel",
    value: { width: 1024, height: 768 },
  });
  const [innerStageSize, setInnerStageSize] = useState({
    width: resizeState?.value?.width || 1024,
    height: resizeState?.value?.height || 768,
  });
  const [overlayEditorVisible, setOverlayEditorVisible] = useState(false);
  const [overlayGeneratedState, setOverlayGeneratedState] = useState({
    visible: false,
    generatedImages: [],
    loading: false,
  });
  const [generatedPromptState, setGeneratedPromptState] = useState({
    prompt: [],
    loading: false,
  });

  const updateGeneratePromptState = (updates) =>
    setGeneratedPromptState((prev) => ({ ...prev, ...updates }));
  const updateGeneratorState = (updates) =>
    setOverlayGeneratedState((prev) => ({ ...prev, ...updates }));
  const updateFormState = (updates) =>
    setOverlayFormState((prev) => ({ ...prev, ...updates }));
  const updateElements = (updates) =>
    setElements((prev) => ({ ...prev, ...updates }));
  const updateResizeState = (updates) =>
    setResizeState((prev) => ({ ...prev, ...updates }));
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      messageContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);

  const handlePrompt = async (e) => {
    e.preventDefault();
    let inputPrompt = e.target[0].value;
    if (inputPrompt.trim() == "") {
      return;
    }
    let query = inputPrompt.toLowerCase().trim();
    if (
      query == "hi" ||
      query == "hello" ||
      query == "what can you do for me" ||
      query == "helo" ||
      query == "hii" ||
      query == "hey" ||
      query == "how are you"
    ) {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "hello" },
      ]);
      setInputVal("");
    } else {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "" },
      ]);
      setInputVal("");
      setLoading(true);
      getUnstructured(inputPrompt);
    }
  };

  const getUnstructured = async (inputPrompt, imageCnt, ratio) => {
    let config = {
      method: "post",
      url:
        region == "IND"
          ? ""
          : "https://us-central1-generativeai-coe.cloudfunctions.net/croma_image_generation",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        prompt: inputPrompt,
        number_of_images: Number(imageCount),
        aspect_ratio: aspectRatio,
      },
    };

    let config1 = {
      method: "post",
      url:
        region == "IND"
          ? ""
          : "https://us-central1-generativeai-coe.cloudfunctions.net/croma_signed_url",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        bucket_name: "croma_images",
        folder_name: "temp",
      },
    };
    try {
      // let response = tempResp;
      let response = await axios(config);
      if (response != undefined) {
        console.log(response);
        if (response.data.generated_images == "Succesfully Generated") {
        }
        const signedResponse = await axios(config1);
        console.log("signedResponse", signedResponse);
        if (signedResponse != undefined) {
          setLoading(false);

          if (signedResponse.data.signed_urls.length == 0) {
            setGeneratedData([
              ...generatedData,
              {
                question: inputPrompt,
                summary: "Empty",
                generated_image: signedResponse.data.signed_urls,
              },
            ]);
          } else {
            setGeneratedData([
              {
                question: inputPrompt,
                summary: "generated data",
                generated_image: signedResponse.data.signed_urls,
              },
            ]);
          }
        }
      }
    } catch (error) {
      console.log("data error", error);
      setLoading(false);
      setGeneratedData([
        ...generatedData,
        {
          question: inputPrompt,
          summary: "Answer Not Found",
          img_search_data: [],
          tuning_data: "",
          results: "",
        },
      ]);
    }
  };

  const handleEdit = (image) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = image;
    img.onload = () => {
      // Ensure image is loaded before calculating width and height
      const newImage = {
        src: img.src,
        id: uuidv4(),
        x: 50,
        y: 50,
        width: img.naturalWidth, // Using naturalWidth and naturalHeight
        height: img.naturalHeight,
      };
      updateElements({ images: [newImage] });
    };
    setOverlayEditorVisible(true);
  };

  const handleExportForGeneration = () => {
    setSelectedElement({ id: "", type: "", element: null });
    if (!overlayStageRef.current) return;
    const stage = overlayStageRef.current;
    const group = stage.findOne("Group");

    if (!group) return;

    try {
      // Store current stage state
      const currentScale = stage.scaleX();
      const currentPosition = { x: stage.x(), y: stage.y() };
      const rect = group.findOne("Rect");

      // Store original rect properties
      const originalRectProps = {
        fill: rect?.fill(),
        shadowColor: rect?.shadowColor(),
        shadowBlur: rect?.shadowBlur(),
        shadowOffsetX: rect?.shadowOffsetX(),
        shadowOffsetY: rect?.shadowOffsetY(),
        cornerRadius: rect?.cornerRadius(),
      };

      // Reset stage and rect properties
      if (rect) {
        rect.shadowColor("transparent");
        rect.shadowBlur(0);
        rect.shadowOffsetX(0);
        rect.shadowOffsetY(0);
        rect.cornerRadius(0);
        rect.fill("white"); // Set white background
      }

      stage.scale({ x: 1, y: 1 });
      stage.position({ x: 0, y: 0 });

      // Use resizeState dimensions
      const width = resizeState.value.width;
      const height = resizeState.value.height;

      const scaleX = width / innerStageSize.width;
      const scaleY = height / innerStageSize.height;
      stage.scale({ x: scaleX, y: scaleY });
      // Apply clip to match exact dimensions
      group.clip({
        x: 0,
        y: 0,
        width: width,
        height: height,
      });

      // Export with exact dimensions
      const dataUrl = group.toDataURL({
        pixelRatio: 1,
        width: width,
        height: height,
        mimeType: "image/png",
      });

      // Convert base64 to file
      const binaryData = atob(dataUrl.split(",")[1]);
      const array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        array[i] = binaryData.charCodeAt(i);
      }
      const file = new File([array], "overlay.png", { type: "image/png" });

      // Restore original stage state
      stage.scale({ x: currentScale, y: currentScale });
      stage.position(currentPosition);
      group.clip(null);

      if (rect) {
        Object.entries(originalRectProps).forEach(([prop, value]) => {
          if (value !== undefined) rect[prop](value);
        });
      }
      setOverlayEditorVisible(false);
      return file;
    } catch (error) {
      console.error("Export error:", error);
      return null;
    }
  };

  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />
      <CRow className="p-0 m-0" xs={{ gutter: "5px" }}>
        <CCol className=" m-0 ms-1 p-2 side-tab-container">
          {Tabs.map(([label, ID, TabIcon]) => (
            <CCol
              className={` side-tab py-2 ${
                selectedTab === ID && "side-tab-active"
              }`}
              onClick={() => {
                setSelectedTab(ID);
                setGeneratedData([]);
                updateFormState({
                  prompt: "",
                  numberOfImages: 1,
                  selectedImage: null,
                });
                updateGeneratePromptState({
                  prompt: [],
                  loading: false,
                });
                updateGeneratorState({
                  visible: false,
                  generatedImages: [],
                  loading: false,
                });
              }}
            >
              <TabIcon size={20} />
              <span className="side-tab-span">{label}</span>
            </CCol>
          ))}
        </CCol>
        <CCol
          className="m-0 p-0 side-tab-nested-container "
          style={{ overflow: "hidden" }}
        >
          {console.log("selectedTab", selectedTab)}
          {selectedTab != "OverlayImage" && selectedTab != "backdrop_images" ? (
            <CCol className="w-100 d-flex align-items-center justify-content-between px-2 py-1 side-tab-nested-content m-0 my-2">
              {selectedTab === "ImageGenerator"
                ? "AI Image Generator"
                : selectedTab === "fashion"
                ? "Product Images"
                : selectedTab === "logo_images"
                ? "Logos"
                : selectedTab === "furniture"
                ? "Furniture"
                : selectedTab === "OverlayImage"
                ? ""
                : selectedTab === "backdrop_images"
                ? ""
                : "Text"}
            </CCol>
          ) : null}
          {selectedTab === "ImageGenerator" ? (
            <ImageGenerator
              handlePrompt={handlePrompt}
              inputVal={inputVal}
              generatedData={generatedData}
              setInputVal={setInputVal}
              loading={loading}
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              aspectRatio={aspectRatio}
              setAspectRatio={setAspectRatio}
              imageCount={imageCount}
              setImageCount={setImageCount}
            />
          ) : selectedTab === "OverlayImage" ? (
            <OverlayImage
              formState={overlayFormState}
              updateFormState={updateFormState}
              resizeVisible={overlayEditorVisible}
              resizeState={resizeState}
              updateResizeState={updateResizeState}
              handleExportForGeneration={handleExportForGeneration}
              overlayGeneratedState={overlayGeneratedState}
              updateGeneratorState={updateGeneratorState}
              generatedPromptState={generatedPromptState}
              updateGeneratePromptState={updateGeneratePromptState}
            />
          ) : selectedTab === "Text" ? (
            <TextToolbar
              elements={elements}
              updateElements={updateElements}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
            />
          ) : (
            <OtherImageGallary
              type={selectedTab}
              elements={elements}
              updateElements={updateElements}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
            />
          )}
        </CCol>
        <CCol className="m-0 p-0 side-tab-main-content">
          {selectedTab === "ImageGenerator" ? (
            <ImageChat
              handlePrompt={handlePrompt}
              inputVal={inputVal}
              setInputVal={setInputVal}
              generatedData={generatedData}
              loading={loading}
              messageContainerRef={messageContainerRef}
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              elements={elements}
              updateElements={updateElements}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              updateGeneratePromptState={updateGeneratePromptState}
            />
          ) : selectedTab === "OverlayImage" && !overlayEditorVisible ? (
            <OverlayChat
              formState={overlayFormState}
              updateFormState={updateFormState}
              handleEdit={handleEdit}
              overlayGeneratedState={overlayGeneratedState}
              updateGeneratorState={updateGeneratorState}
              setOverlayEditorVisible={setOverlayEditorVisible}
              updateGeneratePromptState={updateGeneratePromptState}
            />
          ) : selectedTab === "OverlayImage" && overlayEditorVisible ? (
            <OverlayImageEditor
              elements={elements}
              updateElements={updateElements}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              resizeState={resizeState}
              stageRef={overlayStageRef}
              innerStageSize={innerStageSize}
              setInnerStageSize={setInnerStageSize}
            />
          ) : (
            <ImageEditor
              elements={elements}
              updateElements={updateElements}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
            />
          )}
        </CCol>
      </CRow>
    </div>
  );
};

export default ChromaChat;
