import { useEffect, useState } from "react";
import { io } from "socket.io-client";

const useWebSocket = () => {
  const [socket, setSocket] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const [transcript, setTranscript] = useState([]);

  useEffect(() => {
    const newSocket = io("https://audio-censor-301481222425.us-central1.run.app");
    setSocket(newSocket);

    newSocket.on("connection_status", (data) => {
      setConnectionStatus(data.status);
    });

    newSocket.on("transcript", (data) => {
      setTranscript((prev) => [...prev, data]);
    });

    return () => newSocket.disconnect();
  }, []);

  const startRecording = () => {
    if (socket) socket.emit("start_recording");
  };

  const stopRecording = () => {
    if (socket) socket.emit("stop_recording");
  };

  return { connectionStatus, transcript, startRecording, stopRecording };
};

export default useWebSocket;
