import {
  CButton,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import { useState } from "react";
import { FaBold, FaItalic, FaStrikethrough, FaUnderline } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TiExport, TiMinus, TiPlus } from "react-icons/ti";
const fontList = [
  // Web-safe fonts
  "Arial",
  "Verdana",
  "Helvetica",
  "Tahoma",
  "Trebuchet MS",
  "Times New Roman",
  "Georgia",
  "Garamond",
  "Courier New",
  "Brush Script MT",

  // Google Fonts
  "Roboto",
  "Open Sans",
  "Lato",
  "Montserrat",
  "Oswald",
  "Raleway",
  "Poppins",
  "Merriweather",
  "Ubuntu",
  "Playfair Display",
  "Nunito",
  "PT Serif",
  "Dancing Script",
  "Pacifico",
  "Abril Fatface",

  // System fonts
  "Segoe UI",
  "Lucida Grande",
  "Geneva",
  "Cambria",
  "Palatino Linotype",
];
const EditorToolBar = ({
  handleExport,
  selectedElement,
  onRemove,
  handleStyleChange,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  return (
    <CCol
      className="w-100 d-flex align-items-center justify-content-end gap-2"
      style={{
        position: "absolute",
        top: "10px",
        right: "20px",
        zIndex: "2000",
        overflow: "visible",
      }}
    >
      {selectedElement.id !== "" && (
        <div
          className="d-flex  align-items-center justify-content-end gap-2 px-3 py-2"
          style={{
            backgroundColor: "white",

            height: "45px",
            borderRadius: "10px",
            overflow: "visible",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          }}
        >
          {selectedElement.type === "text" && (
            <CCol className="d-flex flex-column ">
              <select
                value={selectedElement.element?.fontFamily}
                className="font-style-select"
                style={{
                  borderRadius: "5px",
                  height: "26px",
                  border: "1px solid rgb(229, 229, 229)",
                }}
                onChange={(e) =>
                  handleStyleChange("fontFamily", e.target.value)
                }
              >
                {fontList.map((font) => (
                  <option key={font} value={font} style={{ fontFamily: font }}>
                    {font}
                  </option>
                ))}
              </select>
            </CCol>
          )}
          {selectedElement.type === "text" && (
            <CInputGroup className="" style={{ height: "25px" }}>
              <CInputGroupText
                className="p-0 "
                style={{ height: "26px", paddingRight: "1px" }}
              >
                <CButton
                  className="p-1 d-flex align-items-center"
                  color="light"
                  variant="ghost"
                  shape="rounded-start"
                  style={{ height: "25px" }}
                  onClick={() =>
                    handleStyleChange(
                      "fontSize",
                      parseInt(selectedElement.element?.fontSize - 1)
                    )
                  }
                >
                  <TiMinus color="rgb(128, 106, 198)" size={15} />
                </CButton>
              </CInputGroupText>
              <CFormInput
                type="number"
                className="font-size-input px-2 d-flex align-items-center justify-content-center"
                min={1}
                max={144}
                style={{
                  maxWidth: "40px",
                  fontSize: "14px",
                  height: "26px",
                  "-webkit-appearance": "none",
                }}
                value={selectedElement.element?.fontSize}
                onChange={(e) =>
                  handleStyleChange("fontSize", parseInt(e.target.value))
                }
              />
              <CInputGroupText
                className="p-0 "
                style={{ height: "26px", paddingLeft: "1px" }}
              >
                <CButton
                  color="light"
                  className="p-1 d-flex align-items-center"
                  variant="ghost"
                  style={{ height: "25px" }}
                  onClick={() =>
                    handleStyleChange(
                      "fontSize",
                      parseInt(selectedElement.element?.fontSize + 1)
                    )
                  }
                >
                  <TiPlus color="rgb(128, 106, 198)" size={15} />
                </CButton>
              </CInputGroupText>
            </CInputGroup>
          )}

          {selectedElement.type === "text" && (
            <CCol className=" d-flex align-items-center gap-2">
              <div
                style={{
                  width: "fit-content",
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                className={`p-1 px-2 d-flex align-items-center text-style-btn  ${
                  selectedElement.element?.fontWeight === "bold" &&
                  "text-style-btn-active"
                }`}
                onClick={() =>
                  handleStyleChange(
                    "fontWeight",
                    selectedElement.element?.fontWeight === "bold"
                      ? "normal"
                      : "bold"
                  )
                }
              >
                <FaBold size={15} />
              </div>

              {/* Italic button */}
              <div
                style={{
                  width: "fit-content",
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                className={`p-1 px-2 d-flex align-items-center text-style-btn  ${
                  selectedElement.element?.fontStyle === "italic" &&
                  "text-style-btn-active"
                }`}
                onClick={() =>
                  handleStyleChange(
                    "fontStyle",
                    selectedElement.element?.fontStyle === "italic"
                      ? "normal"
                      : "italic"
                  )
                }
              >
                <FaItalic size={15} />
              </div>
              {/* underline button */}
              <div
                style={{
                  width: "fit-content",
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                className={`p-1 px-2 d-flex align-items-center text-style-btn  ${
                  selectedElement.element?.underline === "underline" &&
                  "text-style-btn-active"
                }`}
                onClick={() =>
                  handleStyleChange(
                    "underline",
                    selectedElement.element?.underline === "" ? "underline" : ""
                  )
                }
              >
                <FaUnderline size={15} />
              </div>
              {/* strikeThrough button */}
              <div
                style={{
                  width: "fit-content",
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                className={`p-1 px-2 d-flex align-items-center text-style-btn  ${
                  selectedElement.element?.strikeThrough === "line-through" &&
                  "text-style-btn-active"
                }`}
                onClick={() =>
                  handleStyleChange(
                    "strikeThrough",
                    selectedElement.element?.strikeThrough === ""
                      ? "line-through"
                      : ""
                  )
                }
              >
                <FaStrikethrough size={15} />
              </div>
              {/* Italic button */}
              <div
                style={{
                  cursor: "pointer",
                }}
                className={` d-flex align-items-center text-style-btn  `}
              >
                <CFormInput
                  type="color"
                  id="exampleColorInput"
                  className="p-0"
                  style={{ width: "26px", height: "26px" }}
                  defaultValue={selectedElement.element?.fill}
                  onChange={(e) => {
                    handleStyleChange("fill", e.target.value);
                  }}
                  title="Choose your color"
                />
              </div>
            </CCol>
          )}
          {selectedElement.id !== "" && (
            <CButton
              color={"danger"}
              variant="outline"
              shape="rounded-end"
              className="d-flex align-items-center gap-1 py-1 px-2"
              style={{
                fontSize: "12px",
              }}
              onClick={onRemove}
            >
              <MdDeleteForever style={{ width: "18px", height: "18px" }} />
              Delete
            </CButton>
          )}
        </div>
      )}
      <div
        className="d-flex  align-items-center justify-content-end gap-2 px-3 py-2"
        style={{
          backgroundColor: "white",
          height: "45px",

          borderRadius: "10px",
          overflow: "visible",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        }}
      >
        <CDropdown className="d-inline-block">
          <CDropdownToggle caret={false} custom={true}>
            <CButton
              color={"success"}
              variant="outline"
              className="d-flex align-items-center gap-1 py-1 px-2 "
              style={{
                fontSize: "12px",
              }}
              shape="rounded"
            >
              <TiExport style={{ width: "18px", height: "18px" }} />
              Export
            </CButton>
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem onClick={() => handleExport("png")}>
              Download as PNG
            </CDropdownItem>
            <CDropdownItem onClick={() => handleExport("jpg")}>
              Download as JPG
            </CDropdownItem>
            <CDropdownItem onClick={() => handleExport("pdf")}>
              Download as PDF
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </div>
    </CCol>
  );
};

export default EditorToolBar;
