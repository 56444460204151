import React, { useState } from "react";
import "../styles/image.css";
import { CCol, CSpinner } from "@coreui/react";
import { RiImageEditFill } from "react-icons/ri";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { v4 as uuidv4 } from "uuid";
import { FaTrashCan } from "react-icons/fa6";

import axios from "axios";
const ImageGallery = ({
  id,
  type,
  selectedTab,
  images,
  setImages,
  selectedImages,
  setSelectedImages,
  updateElements,
  handleAddImage,
  getImageData,
}) => {
  const [loadingStates, setLoadingStates] = useState(images.map(() => true));
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleImageSelection = (image) => {
    const isImagePresent = selectedImages.some(
      (selected) => selected.fileName === image.fileName
    );

    if (!isImagePresent) {
      setSelectedImages([...selectedImages, image]);
    }
  };

  const handleDeleteImage = async (image, index) => {
    setDeleteLoading(index);
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_image_generation",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        folder_name: `backdrop_images/${
          selectedTab === "generatedImages"
            ? "generated_images"
            : "altered_images"
        }`,
        delete_image_ids: [image.fileName],
      },
    };

    try {
      // let response = tempResp;
      let response = await axios(config);
      console.log("response get", response);
      if (response != undefined) {
        setDeleteLoading(false);
        const updatedImages = images.filter(
          (img) => img.fileName !== image.fileName
        );
        setImages(updatedImages);
        alert("Image deleted successfully");
      }
    } catch (error) {
      console.log("data error", error);
      setDeleteLoading(false);
    }
  };
  const handleImageLoading = (index, value) => {
    // console.log(`Image loaded: ${index}`);
    setLoadingStates((prevLoadingStates) => {
      const updatedLoadingStates = [...prevLoadingStates];
      updatedLoadingStates[index] = value;
      return updatedLoadingStates;
    });
  };

  return (
    <div className="gallery">
      {images &&
        images.map((imageUrl, index) => (
          <div
            key={imageUrl.url}
            className="galleryItem d-flex flex-column position-relative"
          >
            {loadingStates[index] && (
              <CCol>
                <SkeletonTheme
                  baseColor="rgb(240,241,245)"
                  highlightColor="rgb(251, 251, 251)"
                >
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            )}

            {/* Image Element */}
            <img
              src={imageUrl.url}
              alt={imageUrl.fileName}
              width="100%"
              height="auto"
              style={{
                display: loadingStates[index] ? "none" : "block",
              }}
              onLoadStart={() => handleImageLoading(index, true)}
              onLoadCapture={() => handleImageLoading(index, false)}
              onClick={() =>
                id !== "otherImages"
                  ? handleImageSelection(imageUrl)
                  : type === "backdrop_images"
                  ? updateElements({
                      background: { imageUrl: imageUrl.url, id: uuidv4() },
                    })
                  : handleAddImage(imageUrl.url)
              }
            />
            {type === "backdrop_images" && (
              <div
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                  cursor: "pointer",
                  zIndex: 1000,
                }}
              >
                {deleteLoading === index ? (
                  <CSpinner style={{ width: "15px", height: "15px" }} />
                ) : (
                  <FaTrashCan
                    onClick={() => handleDeleteImage(imageUrl, index)}
                    size={15}
                    className="text-danger"
                  />
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default ImageGallery;
