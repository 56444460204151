import React, { useState } from "react";
import CheckBox from "../../components/CheckBox";
import ProductCard from "./ProductCard";
import { CButton, CTooltip } from "@coreui/react";
import { FaTable } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
const ProductSection = (props) => {
  const [filter, setFilter] = useState({
    all: false,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
  });
  const handleChangeFilter = (name) => {
    setFilter((prevState) => {
      const newState = { ...prevState, [name]: !prevState[name] };

      // If "all" is checked or unchecked, update all other checkboxes
      if (name === "all") {
        const newCheckedState = !prevState.all;
        return {
          all: newCheckedState,
          checkbox1: newCheckedState,
          checkbox2: newCheckedState,
          checkbox3: newCheckedState,
          checkbox4: newCheckedState,
        };
      }

      // If any individual checkbox is unchecked, uncheck "all"
      if (!newState[name]) {
        return { ...newState, all: false };
      }

      // If all individual checkboxes are checked, check "all"
      const allChecked =
        newState.checkbox1 &&
        newState.checkbox2 &&
        newState.checkbox3 &&
        newState.checkbox4;
      return { ...newState, all: allChecked };
    });
  };
  function filterDataBySubcategory(data) {
    const subcategoryMap = {};

    data.forEach((item) => {
      if (!subcategoryMap[item.Subcategory]) {
        subcategoryMap[item.Subcategory] = [];
      }
      subcategoryMap[item.Subcategory].push(item);
    });

    return subcategoryMap;
  }
  // console.log(Object.values(filterDataBySubcategory(props.data)));
  return (
    <div>
      <h6
        style={{ margin: "10px" }}
        className="text-medium-emphasis d-flex justify-content-start gap-2 align-items-center"
      >
        {props.title === "Rings"
          ? "Miscellaneous Rings"
          : props.title === "Jewelry"
          ? "Miscellaneous Jewelry"
          : props.title}
        <CTooltip content="View all Queries" className="font-sm">
          <CButton
            className="p-0 m-0 d-flex align-items-center justify-content-center text-medium-emphasis"
            style={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              // boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            }}
            variant="ghost"
            color="dark"
            onClick={() => {
              props.getCategoryData(props.title);
              props.setVisible(true);
              props.setCategorySelected(
                props.title === "Rings"
                  ? "Miscellaneous Rings"
                  : props.title === "Jewelry"
                  ? "Miscellaneous Jewelry"
                  : props.title
              );
            }}
          >
            <FaTable
              className="m-0"
              // style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              size={"1rem"}
            />
          </CButton>
        </CTooltip>
        <CTooltip content="View Weekly Trend" className="font-sm">
          <CButton
            className="p-0 m-0 d-flex align-items-center justify-content-center text-medium-emphasis"
            style={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              // boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            }}
            variant="ghost"
            color="dark"
            onClick={() => {
              props.getTrendHistory(props.title);
              props.setVisible4(true);
              props.setCategorySelected(
                props.title === "Rings"
                  ? "Miscellaneous Rings"
                  : props.title === "Jewelry"
                  ? "Miscellaneous Jewelry"
                  : props.title
              );
            }}
          >
            <FaChartLine
              className="m-0"
              // style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              size={"1.1rem"}
            />
          </CButton>
        </CTooltip>

        {/* Filter Component */}
        {/* <div
          style={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            width: "max-content",
            borderRadius: "40px",
          }}
          className="p-2 d-flex gap-3 mt-2 bg-white"
        >
          <CheckBox
            label="All"
            checked={filter.all}
            onChange={() => handleChangeFilter("all")}
          />
          <CheckBox
            label="Diamond"
            checked={filter.checkbox1}
            onChange={() => handleChangeFilter("checkbox1")}
          />
          <CheckBox
            label="Gold"
            checked={filter.checkbox2}
            onChange={() => handleChangeFilter("checkbox2")}
          />
          <CheckBox
            label="Silver"
            checked={filter.checkbox3}
            onChange={() => handleChangeFilter("checkbox3")}
          />
          <CheckBox
            label="Platinum"
            checked={filter.checkbox4}
            onChange={() => handleChangeFilter("checkbox4")}
          />
        </div> */}
      </h6>
      <div style={{ width: "100%", overflow: "auto" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(1000,120px)",
            gap: "10px",
            margin: "10px",
          }}
        >
          {Object.values(filterDataBySubcategory(props.data)).map((ele) => {
            return (
              <ProductCard
                ele={ele}
                setVisible2={props.setVisible2}
                setProduct={props.setProduct}
                setVisible={props.setVisible}
                trendImages={props.trendImages}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductSection;