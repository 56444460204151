import React, { useState, useRef, useEffect } from "react";
import {
  CNavItem,
  CNavLink,
  CSidebar,
  CSidebarNav,
  CForm,
  CCard,
  CCol,
  CButton,
  CSpinner,
  CAlert,
  CAvatar,
  CImage,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
  COffcanvasBody,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalHeader,
  CTooltip,
  CRow,
  CNavTitle,
} from "@coreui/react";
import { FaMedal } from "react-icons/fa";
import { GiClothes, GiSofa } from "react-icons/gi";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilWarning, cilSend } from "@coreui/icons";
import { ChatMessage } from "../../components/ChatMessage";
import aiImage from "../../assets/images/OIG.png";
import { RiChatSmile3Fill, RiImageEditFill } from "react-icons/ri";
import db from "../../database/db.json";
import { FaDatabase } from "react-icons/fa6";
import { Link as ScrollLink, Element } from "react-scroll";
import OutputTable from "../../components/OutputTable";
import ChartTypes from "../../components/ChartTypes";
import AdvanceBarChart from "../../components/AdvanceBarChart";
import BarLoader from "../../components/BarLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductSection from "../titan/ProductSection";
import { IoArrowBackCircle } from "react-icons/io5";
import { Navbar } from "../croma/components/Navbar";
const barDataStructure = JSON.stringify({
  labels: [],
  datasets: [],
});
function generateRGBAColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  const a = 0.7;
  return {
    bg: `rgba(${r}, ${g}, ${b}, ${a})`,
    border: `rgb(${r}, ${g}, ${b})`,
  };
}
const TrendSearchSpotting = () => {
  const { id } = useParams();
  console.log("id", id);
  const navigate = useNavigate();
  const routeLocation = useLocation();
  const trendingDataState = routeLocation.state;
  const searchState = routeLocation.state.searchState;
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const messageContainerRef = useRef(null);
  const [generatedData, setGeneratedData] = useState([]);
  const [height, setHeight] = useState(30);
  const [product, setProduct] = useState();
  const [selectedImg, setSelectedImg] = useState();
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [location, setLocation] = useState("Texas");
  const [categoryData, setCategoryData] = useState();
  const [categorySelected, setCategorySelected] = useState();
  const [highlightedSection, setHighlightedSection] = useState(null);
  const [trendChart, setTrendChart] = useState();
  const [trendImages, setTrendImages] = useState([]);
  const [date, setDate] = useState({
    start_dt: "2024-11-04",
    end_dt: "2024-11-11",
  });

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      messageContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const [category, setCategory] = useState(
    id === "fashion" ? "Clothing" : "Furniture"
  );

  useEffect(() => {
    scrollToBottom();
  }, [generatedData]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handlePrompt(event);
    }
    if (event.key === "Enter" && event.shiftKey) {
      adjustHeight(event.target);
    }
  };
  const adjustHeight = (element) => {
    const maxHeight = 90;
    const lineHeight = 16; // Adjust according to your textarea's line-height
    const lines = element.value.split("\n");
    const rowsFromLines = Math.max(lines.length, 1); // Ensure at least one row

    // If there are no newline characters, but text exists, consider it as a single line
    const singleLineLength = lines.reduce((acc, line) => acc + line.length, 0);
    const rowsFromLength = Math.ceil(singleLineLength / element.cols); // Determine rows based on text length

    const rows = Math.max(rowsFromLines, rowsFromLength);

    const newHeight = Math.min(rows * lineHeight, maxHeight);
    setHeight(newHeight);
  };
  const handleChange = (event) => {
    setInputVal(event.target.value);
  };
  const handlePrompt = async (e) => {
    let inputPrompt = inputVal;
    if (inputPrompt.trim() == "") {
      return;
    }
    let query = inputPrompt.toLowerCase().trim();
    if (
      query == "hi" ||
      query == "hello" ||
      query == "what can you do for me" ||
      query == "helo" ||
      query == "hii" ||
      query == "hey" ||
      query == "how are you"
    ) {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "hello" },
      ]);
      setInputVal("");
    } else {
      setGeneratedData([
        ...generatedData,
        { question: inputPrompt, summary: "" },
      ]);
      setInputVal("");
      setSelectedImg("");
      setLoading(true);
      getUnstructured(inputPrompt);
    }
  };

  const getUnstructured = async (inputPrompt) => {
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/YoungLA_Text_To_SQL",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyAvm2m7VHRZb7GPEZvimSNa3-HU2GP8ZYY",
        "Access-Control-Allow-Origin": "*",
      },
      data: { question: inputPrompt },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        setLoading(false);
        console.log("response", response);

        setGeneratedData([
          ...generatedData,
          {
            question: inputPrompt,
            summary: response.data.query_text,
            data: response.data.values,
            explanation: response.data.explanation,
          },
        ]);
      }
    } catch (error) {
      console.log("data error", error);
      setLoading(false);
      setGeneratedData([
        ...generatedData,
        {
          question: inputPrompt,
          summary: "answer not found.",
        },
      ]);
    }
  };

  function renameCategory(categoryName) {
    if (categoryName === "Jewelry") {
      return "Miscellaneous Jewelry";
    }
    if (categoryName === "Rings") {
      return "Miscellaneous Rings";
    }
    return categoryName;
  }

  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    // console.log(day, month, year);
    return `${day} ${month} ${year}`;
  }

  const getCategoryData = async (category) => {
    setCategoryData();
    setLoading3(true);
    let config = {
      method: "post",
      // url: " https://us-central1-generativeai-coe.cloudfunctions.net/titan_Category",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/YongLA_Category",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-API-Key": "AIzaSyAvm2m7VHRZb7GPEZvimSNa3-HU2GP8ZYY",
      },
      data: {
        location: location,
        current_date_time: date.end_dt,
        Type: id === "fashion" ? "Clothing" : "Furniture",
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        console.log("response", response.data);
        setCategoryData(groupBySubcategory(response.data.data, category));
        setLoading3(false);
      }
    } catch (error) {
      console.log("data error", error);
      setLoading3(false);
      setCategoryData([]);
    }
  };

  function groupBySubcategory(data, category) {
    let temp;
    data.forEach((categoryArray) => {
      if (categoryArray[0]?.Category === category) {
        temp = categoryArray;
      } else {
      }
    });
    return temp;
  }
  function filterDataBySubcategory(data) {
    const subcategoryMap = {};

    data.forEach((item) => {
      if (!subcategoryMap[item.Subcategory]) {
        subcategoryMap[item.Subcategory] = [];
      }
      subcategoryMap[item.Subcategory].push(item);
    });

    return subcategoryMap;
  }
  const structureBarChartData = (data, resp) => {
    let labels = Object.values(data)[0][0].Date;
    let datasets = [];
    let max = -Infinity;
    Object.entries(data).map(([key, val], index) => {
      datasets.push(val[0].rank);
    });
    console.log(Object.values(data)[0][0].Date);
    return (resp = {
      labels,
      datasets,
    });
  };
  const getTrendHistory = async (type) => {
    setTrendChart();
    let config = {
      method: "post",
      // url: " https://us-central1-generativeai-coe.cloudfunctions.net/titan_Timeseries_data",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/YoungLA_Timeseries",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyAvm2m7VHRZb7GPEZvimSNa3-HU2GP8ZYY",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        location: location,
        Type: category,
      },
    };
    try {
      let response = await axios(config);
      if (response != undefined) {
        setLoading(false);
        console.log("response", response);

        let filtered = filterDataBySubcategory(
          groupBySubcategory(response.data, type)
        );

        let graphStructure = JSON.parse(barDataStructure);
        let res = structureBarChartData(filtered, {});
        graphStructure.labels = res.labels;
        res.datasets.forEach((ele, i) => {
          const color = generateRGBAColor();
          graphStructure.datasets.push({
            label: Object.keys(filtered)[i],
            borderColor: "rgba(255,255,255,.55)",
            backgroundColor: "",
            pointBackgroundColor: color.bg,
            type: "line",
            fill: false,
            pointRadius: 3,
            pointHoverRadius: 5,
            datalabels: {
              display: true,
            },
            backgroundColor: color.bg,
            borderColor: color.border,
            borderWidth: 1.5,
            data: ele,
            options: {
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    color: "rgb(255, 99, 132)",
                    display: false,
                  },
                },
              },
              plugins: {
                pan: {
                  enabled: true,
                  mode: "x",
                },
                limits: {
                  x: { min: 1, max: 7 },
                },
                zoom: {
                  pan: {
                    enabled: true,
                  },
                },
              },
            },
          });
        });
        setTrendChart(graphStructure);
      }
    } catch (error) {
      console.log("data error", error);
      let graphStructure = JSON.parse(barDataStructure);
      setTrendChart(graphStructure);
    }
  };
  const handleQueryClick = (el) => {
    console.log("el", el);
    if (el?.Query) {
      navigate(`/trendcraft/${id}/search-trends/design-studio`, {
        state: { prompt: el.Query },
      });
    } else {
      navigate(`/trendcraft/${id}/search-trends/design-studio`, {
        state: { prompt: el[1] },
      });
    }
  };
  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <Navbar />
      <CRow className="p-2">
        <CCol xs={3} className="pe-1">
          <CSidebar className="new-sidebar">
            <CSidebarNav className="py-0">
              {loading2 ? <BarLoader height={"50"} width={"30"} /> : null}
              <CNavTitle
                style={{
                  fontSize: "16px",
                  borderBottom: "1px solid rgb(237, 237, 237)",
                  textTransform: "capitalize",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                className="py-1 ps-1 pt-0 d-flex align-items-center mb-2"
              >
                {id === "fashion" ? (
                  <GiClothes
                    className="nav-icon "
                    style={{ color: "rgb(95, 95, 95)" }}
                  />
                ) : (
                  <GiSofa
                    className="nav-icon "
                    style={{ color: "rgb(95, 95, 95)" }}
                  />
                )}{" "}
                {id === "fashion" ? "Fashion" : "Furniture"}
              </CNavTitle>
              {trendingDataState.data &&
                Object.entries(trendingDataState.data).length > 0 &&
                Object.entries(trendingDataState.data).map(
                  ([parentCateg, category]) =>
                    Object.values(category).map((el, index) => {
                      return (
                        <CTooltip content={renameCategory(el[0].Category)}>
                          <CNavItem>
                            <ScrollLink
                              to={el[0].Category.toLowerCase()}
                              smooth={true}
                              duration={500}
                              offset={-70}
                              containerId="containerElement"
                              onClick={() => {
                                setHighlightedSection(
                                  el[0].Category.toLowerCase()
                                );
                                setTimeout(() => {
                                  setHighlightedSection(null);
                                }, 1000);
                              }}
                            >
                              <CNavLink
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "rgb(102, 102, 102)",
                                  cursor: "pointer",
                                }}
                                className="ps-4 py-2"
                              >
                                {renameCategory(el[0].Category)}
                              </CNavLink>
                            </ScrollLink>
                          </CNavItem>
                        </CTooltip>
                      );
                    })
                )}
            </CSidebarNav>
          </CSidebar>
        </CCol>
        <CCol sm={9} className="ps-1 position-relative">
          <Element
            id="containerElement"
            className="chat-container relative pt-2 gap-0 "
          >
            <CCol className="d-flex align-items-center justify-content-between px-3">
              {searchState && (
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "rgb(102, 102, 102)",
                  }}
                >
                  {`${id === "fashion" ? "Fashion" : "Furniture"} trends for `}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "rgb(98, 98, 98)",
                    }}
                  >
                    {searchState?.location}
                  </span>{" "}
                  from{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "rgb(98, 98, 98)",
                    }}
                  >
                    {searchState?.date.start_dt !== "0"
                      ? `${formatDate(searchState?.date.start_dt)} to `
                      : "Till "}
                    {formatDate(searchState?.date.end_dt)}
                  </span>
                </div>
              )}
              <div className={"d-flex align-items-center gap-2"}>
                <CButton
                  variant="outline"
                  className="custom-btn py-1 px-3 d-flex align-items-center gap-2"
                  style={{ fontSize: "14px" }}
                  onClick={() =>
                    navigate(`/trendcraft/${id}/search-trends/design-studio`)
                  }
                >
                  <RiImageEditFill /> Launch Studio
                </CButton>
              </div>
            </CCol>

            {trendingDataState.data == undefined ? (
              <div>
                <BarLoader height={"50"} width={"30"} />
              </div>
            ) : Object.entries(trendingDataState.data).length == 0 ? (
              <div
                style={{ color: "#832729", fontSize: "18px" }}
                className="d-flex align-items-center justify-content-center"
              >
                No Data Available
              </div>
            ) : (
              Object.entries(trendingDataState.data).map(
                ([parentCateg, category]) => {
                  return (
                    <div
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      }}
                      className="border p-2 m-2 rounded-2"
                    >
                      <h5 style={{ margin: "10px" }}>{parentCateg}</h5>
                      {Object.values(category).map((categ, index) => {
                        return (
                          <Element
                            key={categ[0].Category}
                            name={categ[0].Category.toLowerCase()}
                            id={categ[0].Category.toLowerCase()}
                            className={
                              highlightedSection ==
                              categ[0].Category.toLowerCase()
                                ? "highlight"
                                : ""
                            }
                          >
                            <ProductSection
                              getCategoryData={getCategoryData}
                              getTrendHistory={getTrendHistory}
                              setCategorySelected={setCategorySelected}
                              title={categ[0].Category}
                              data={categ}
                              setProduct={setProduct}
                              setVisible2={setVisible2}
                              setVisible={setVisible}
                              setVisible4={setVisible4}
                              trendImages={trendImages}
                            />
                          </Element>
                        );
                      })}
                    </div>
                  );
                }
              )
            )}
          </Element>
          <CModal
            visible={visible2}
            onClose={() => {
              setVisible2(false);
              setProduct();
            }}
            size="md"
            alignment="center"
            scrollable
          >
            <CModalHeader className="p-2 d-flex align-items-end gap-2">
              {product && product.data[0].Subcategory}{" "}
              <span style={{ color: "#832729" }} className="font-md">
                {date.start_dt !== "0"
                  ? `${formatDate(date.start_dt)} to `
                  : "Till "}
                {formatDate(date.end_dt)}
              </span>
            </CModalHeader>
            <CModalBody>
              <div className="d-flex gap-2 font-md w-100">
                <CImage
                  className="rounded-2"
                  style={{
                    height: "150px",
                    width: "150px",
                    minHeight: "150px",
                    minWidth: "150px",
                    objectFit: "fill",
                    boxShadow: " rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    border: "none",
                    fontSize: "8px",
                  }}
                  // src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAM1BMVEXf39+goKDe3t6dnZ3i4uKmpqabm5vS0tLV1dW3t7fLy8vDw8O6urqqqqqioqLKysqwsLA0gLe7AAAE+ElEQVR4nO2di3KDIBBFCaB5iv7/15aLihCNtcmAKd470+Yh4npcloVIIuQGiS2FNlQjwoeP69lcfL6/iLaIvkoR6JeDokRfbDwlX6WQi7sP1YfWPB9PPGmxgtj2uGxcj1yvaConZzXFZoSPT7VP+84rGV9GcKPK3XMd2Y+Xz3ik/++vQ3y1liyeTjM66dDy6XSDFyI2cH5VIk9Z3B7sGNJaLrlUzeuSwzUe/ma7y/jc1ixcrloIsbV8ySKDVXc9jMiADCDGAzKANnemBYsIGBMh+gEF0Q3IAGI8YH4AEYFgQBBEALEtCDqCIAKIDBgPICIAA314DIcHIMgAIgMygMiA+QFEBGQAsS1wPhEigv6uwb1t2FtkQAYQGZABRAZkADE/wHiBEMiADCASEIQgiABi30g/gNgvkAFEBmQAkQEZQGTgGOi9bdhb9AIygDhe4GeuUHIG+nOlNTB9W9CPj3W9pDUxeUzUplZOtZUKFb6uVbzNv+3+nxPbmJxBdYLM6X2pIhgsXeONKoWB6q5vKwuD1DHRMqhv7/cJphAG6vb27jITg6QUdKUsg7e6eGdaUQwuf5cojIG+23Sg/otUlZFBUg0MpL6rv+YFTUkMahsTLYMhTzLB32pq1GD/LAwS17/AwNicyRJQv1BwDPL0C4nr7xnYmOgZ2JO6nqW83Kv11pGPQWrNGChlzhrrCbW81t/BIFdbCPzgrIcv3NTNqicUFw8mP7hOi0ovtVmJCQUzuExJo26+wg9SQxgYSN8vGP9NrvbNh/oGP8jBQIV9YxV8Ae564lRUjhQxOIUMrpEfmP0YZBgvBAyQOPuNZjpvVVV7tYWsDIy/vP22tja+MajrTc0ZlDN/0AYx8aQeuj+kvoQnbROpp8SxLD+IGJi6k26arI1PWcItwqBQMgObLnf3+8PUYQxUnXZlg/dKZmCGyfbQCRTGFE+eUdR4QcXjhQUZpdy1CLsGla9vTFz/NgauKUg4gjksAzQFKfwnc55BtniQVFv9oDcjTJ5z+kFaCPNcOQ4ECJF2Qzckj0gZxnIZx867MnAUbMpwG60JRtPHYWDP8VTXozVhYzgOA1XXXTuZcdmBQWqNcyjzmGhcxqi61t0kOvy8jhCNzxXLnUsLPcABiD+P1Xc/2VxU3/iCwegB/e8ujXZMjSEjg/TxYImBqpwHLBzdT7gXxUBFc6ruQTWvDjs1hqLaAsaNkR8YVb06rJwaQ1lzqtHYGQiatQUDTXYGieuftYWhIbz0AynaukQ/iO7BMK9jwbCLm1w1hcWDWzgi/A2Bm0ZQp7zzykk1tYXxvuVfELjP4FoUrkRJDFo7Mj7fB20ZoegbSuJZKQzceMEvZIiO/no/3adQp0L6BTAIftZ3o/qyuHupCD9oewcIbjwY3GFpkUroLFpn8YMc8eBxe1ulMPhs/YIpIh58KDIogUFnqk/13+OB7QyWlyz68P8F6xtTH2D2s/Phr6wvbX9+L7mBXPMt/sWa7+QW/gMGycXvEiUDiAzIACIDMoDIgPkBRAT0A4jfj0QGELsFMoDIgH0jRAZkALFvdDHx8BDYL0R3Ch9WbAtsCxAZkAFEBpxHgjivTAYQGTAeQBw30g8gMiADiAw4eQCRAdsCRARkAJEB4wHEcSMZQGTAeADRDcgAIgPmSE6EQASCDCAyYI4EEQH9AMqzuv67RQRsCxARkIHVD9yhN0e3gqRsAAAAAElFTkSuQmCC"
                  src={
                    (product && product.img) ||
                    (product && db[product.data[0].Category])
                  }
                  alt={product && product.data[0].Subcategory}
                />
                <div
                  className="w-100"
                  style={{ maxHeight: "75vh", overflowY: "scroll" }}
                >
                  {product?.data
                    .sort((a, b) => a.TermRank - b.TermRank)
                    .map((el) => {
                      return (
                        <div
                          style={{
                            boxShadow:
                              " rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                            cursor: "pointer",
                          }}
                          className="text-start border rounded-2 mb-1 w-100 p-2 font-md"
                        >
                          <div
                            className="d-flex justify-content-between"
                            onClick={() => handleQueryClick(el)}
                          >
                            <div className="d-flex gap-1">
                              <b>Query: </b>
                              <CTooltip content={el?.Query} className="font-sm">
                                <div
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    width: "150px",
                                  }}
                                >
                                  {el?.Query}
                                </div>
                              </CTooltip>
                            </div>
                            <div
                              className={`${
                                el.Previous_TermRank
                                  ? el.Previous_TermRank < el.TermRank
                                    ? "text-danger"
                                    : "text-success"
                                  : ""
                              }`}
                            >
                              {el.Previous_TermRank ? (
                                el.Previous_TermRank < el.TermRank ? (
                                  <FaArrowDown className="text-danger" />
                                ) : (
                                  <FaArrowUp className="text-success" />
                                )
                              ) : (
                                ""
                              )}
                              <b>
                                <FaMedal /> Rank:{" "}
                              </b>{" "}
                              <span
                                className={`${
                                  el.Previous_TermRank
                                    ? el.Previous_TermRank < el.TermRank
                                      ? "text-danger"
                                      : "text-success"
                                    : ""
                                }`}
                              >
                                {" "}
                                {el?.TermRank}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-1">
                            <b className="">Queries: </b> {el?.Queries}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </CModalBody>
          </CModal>
          <CModal
            visible={visible4}
            onClose={() => {
              setVisible4(false);
              setCategorySelected("");
            }}
            size="md"
            alignment="center"
            scrollable
          >
            {/* <CModalHeader className="p-2">Evergreen Trend Analysis</CModalHeader> */}
            <CModalBody>
              <AdvanceBarChart
                dataLabels
                label={
                  "Evergreen Trend Analysis for " +
                  categorySelected +
                  ` ${
                    date.start_dt !== "0"
                      ? `${formatDate(date.start_dt)} to `
                      : "Till "
                  }
              ${formatDate(date.end_dt)} `
                }
                data={trendChart}
                info={""}
                drillDownLevel={0}
                showLegend={true}
                disableDownload={true}
                stacked
                noWidget
                stackedx
                height={40}
                singleval
                titleCenter
                disableInfo
                reverse
              ></AdvanceBarChart>
            </CModalBody>
          </CModal>
          <COffcanvas
            placement="end"
            visible={visible}
            onHide={() => {
              setVisible(false);
              setCategoryData();
              setCategorySelected();
            }}
            style={{ width: "70%", backgroundColor: "#fff" }}
          >
            <COffcanvasHeader className="p-2">
              <COffcanvasTitle className="d-flex gap-1 align-items-center font-lg p-0">
                All Queries for {categorySelected}{" "}
                <span style={{ color: "#832729" }} className="p-1">
                  {date.start_dt !== "0"
                    ? `${formatDate(date.start_dt)} to `
                    : "Till "}
                  {formatDate(date.end_dt)}
                </span>
              </COffcanvasTitle>
              <CCloseButton
                className="text-reset"
                onClick={() => setVisible(false)}
              />
            </COffcanvasHeader>
            <COffcanvasBody style={{ paddingBottom: "100px" }}>
              <div
                style={{
                  // paddingLeft: "190px",
                  paddingRight: "0px",
                  marginTop: "10px",
                  overflowY: "auto",
                  height: "90vh",
                  textAlign: "left",
                  paddingBottom: "80px",
                  transition:
                    "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
                }}
              >
                {categoryData == undefined ? (
                  <div
                    style={{
                      width: "100%",
                      height: "70vh",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <BarLoader height={"50"} width={"30"} />
                  </div>
                ) : categoryData.length == 0 ? (
                  <div
                    style={{ color: "#832729", fontSize: "18px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    No Data Available
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "70vh",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: "16px" }} className="mb-2">
                        <b className="text-medium-emphasis">Location:</b>{" "}
                        {location}
                      </div>
                      {categoryData && (
                        <CCard
                          className="border-0"
                          style={{
                            maxHeight: "400px",
                            maxWidth: "50vw",
                            background: "none",
                            minWidth: "60vw",
                          }}
                        >
                          <OutputTable
                            data={categoryData}
                            handleQueryClick={handleQueryClick}
                          />
                        </CCard>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </COffcanvasBody>
          </COffcanvas>
          <COffcanvas
            placement="end"
            visible={visible3}
            onHide={() => {
              setVisible3(false);
            }}
            style={{ width: "80%", backgroundColor: "#fff" }}
          >
            <COffcanvasHeader className="p-1 px-3">
              <COffcanvasTitle className="d-flex gap-2 align-items-center p-0">
                <RiChatSmile3Fill />
                AI Text to SQL ({location})
              </COffcanvasTitle>
              <CCloseButton
                className="text-reset"
                onClick={() => setVisible3(false)}
              />
            </COffcanvasHeader>
            <COffcanvasBody
              style={{ paddingBottom: "100px", fontSize: "12px" }}
            >
              {generatedData.length == 0 ? (
                <div className="d-grid gap-2 font-md w-100 justify-content-center font-lg">
                  <CCard
                    style={{
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      zIndex: 1,
                      animationDuration: "1300ms",
                    }}
                    className="p-2 px-3 right-to-left"
                  >
                    <div className="mt-1 " style={{ color: "grey" }}>
                      Ask Questions to get the insights from the Trends
                      database.
                    </div>
                  </CCard>
                  <CCard
                    style={{
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      zIndex: 1,
                      animationDuration: "1300ms",
                    }}
                    className="p-2 px-3 right-to-left"
                  >
                    <div className="mt-1 " style={{ color: "grey" }}>
                      Visualize your data with easy to understand tables and
                      graphs with option to add customised graphs as per your
                      need.
                    </div>
                  </CCard>
                </div>
              ) : (
                <>
                  {generatedData?.map((el, i) => {
                    return (
                      <div
                        ref={messageContainerRef}
                        style={{ paddingRight: "20px" }}
                        className={`${selectedImg ? "mb-5" : "mb-3"}`}
                      >
                        <ChatMessage
                          key={i + 1}
                          text={el.question}
                          isUser={true}
                        />
                        {el.summary == "" ? (
                          <div
                            style={{ marginLeft: "10px" }}
                            className="d-flex justify-content-start position-relative gap-2 align-items-start"
                          >
                            <CAvatar
                              style={{
                                width: "35px",
                                height: "35px",
                              }}
                              className="font-md mt-2 border avatar"
                              size="sm"
                              src={aiImage}
                            >
                              {}
                            </CAvatar>
                            <div
                              className={`chat-message other mt-2 align-items-center justify-content-start `}
                              style={{ width: "75%" }}
                            >
                              <SkeletonTheme
                                baseColor="rgb(224 217 247)"
                                highlightColor="rgb(139 123 192)"
                              >
                                <h4
                                  style={{ width: "100%", textAlign: "start" }}
                                >
                                  <Skeleton count={1} width={"50%"} />
                                </h4>
                                <p
                                  style={{ width: "100%", textAlign: "start" }}
                                >
                                  <Skeleton
                                    height={12}
                                    width={"95%"}
                                    count={2}
                                  />
                                </p>
                              </SkeletonTheme>
                            </div>
                          </div>
                        ) : el.summary
                            ?.toLowerCase()
                            .includes(
                              "sorry couldn't find any information regarding that"
                            ) ||
                          el.summary
                            ?.toLowerCase()
                            .includes("answer not found") ||
                          el.data?.length == 0 ? (
                          <div name={i}>
                            <ChatMessage
                              key={i}
                              text={
                                "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
                              }
                              isUser={false}
                              isError={true}
                            />
                          </div>
                        ) : el.summary == "hello" ? (
                          <div
                            style={{ marginLeft: "10px" }}
                            className="d-flex justify-content-start position-relative gap-2 align-items-start"
                          >
                            <CAvatar
                              style={{
                                width: "35px",
                                height: "35px",
                              }}
                              className="font-md mt-2 border avatar"
                              size="sm"
                              src={aiImage}
                            >
                              {}
                            </CAvatar>
                            <div className={`chat-message other mt-2`}>
                              {el.question
                                ?.trim()
                                .toLowerCase()
                                .includes("what can you do for me") ? (
                                <div className="text-start">
                                  <p
                                    className="m-0 mt-1"
                                    style={{ fontWeight: 500 }}
                                  >
                                    Here are some example questions to get you
                                    started:
                                  </p>
                                  <p className="m-0">
                                    What is the average popularity of queries in
                                    each subcategory?
                                  </p>
                                  <p className="m-0">
                                    For Anklets category give me trend analysis
                                    on total number of queries for subcategory.
                                  </p>
                                </div>
                              ) : (
                                <div className="text-start">
                                  Hello! How can I assist you today? <br />
                                  <p
                                    className="m-0 mt-1"
                                    style={{ fontWeight: 500 }}
                                  >
                                    Here are some example questions to get you
                                    started:
                                  </p>
                                  <p className="m-0">
                                    What is the rank for queries in the 'Rings'
                                    category between '2024-05-01' and
                                    '2024-07-31'?
                                  </p>
                                  <p className="m-0">
                                    What is the average popularity of queries in
                                    each subcategory?
                                  </p>
                                  <p className="m-0">
                                    For Anklets category give me trend analysis
                                    on total number of queries for subcategory.
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              style={{ marginLeft: "10px" }}
                              className="d-flex justify-content-start position-relative gap-2 align-items-start text-start"
                            >
                              <CAvatar
                                style={{
                                  width: "35px",
                                  height: "35px",
                                }}
                                className="font-md mt-2 border avatar"
                                size="sm"
                                src={aiImage}
                              >
                                {}
                              </CAvatar>
                              <div
                                className={`chat-message other mt-2 font-md gap-1`}
                                style={{ maxWidth: "85%" }}
                              >
                                <ChartTypes
                                  data={el}
                                  explanations={el.explanation}
                                  page={"sales"}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
              {generatedData.length >= 20 ? (
                <CAlert
                  style={{
                    bottom: 100,
                    position: "fixed",
                    marginLeft: visible ? 450 : 300,
                    zIndex: 11,
                  }}
                  color={"warning"}
                  className="p-2 mb-2 font-md d-flex align-items-center gap-1 w-50"
                >
                  <CIcon icon={cilWarning} size="sm" height={15} width={15} />
                  <div>
                    You have reached the chat limit of 20 questions. Please
                    clear chat to continue asking more questions.
                  </div>
                </CAlert>
              ) : null}
              <CForm
                spellCheck={false}
                style={{
                  bottom: "0px",
                  position: "fixed",
                  width: "77%",
                  paddingBottom: "10px",
                  background: "#fef9f8",
                  backdropFilter: "blur(10px)",
                  zIndex: 11,
                  paddingTop: "0px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CCard
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    width: "100%",
                    padding: "2px",
                    borderRadius: "5px",
                    paddingTop: "14px",
                  }}
                  className=" m-auto"
                >
                  <CCol
                    className="d-flex align-items-end w-100 inputfocus m-auto ps-3"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #9b9b9b",
                      borderRadius: "35px",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                  >
                    <CFormTextarea
                      className="border-0 inputfocus form-control ps-3"
                      value={inputVal}
                      disabled={loading}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      style={{
                        height: `${height}px`,
                        resize: "none",
                        backgroundColor: "white",
                        boxShadow: "none",
                        borderRadius: "8px",
                        fontSize: "12px",
                        marginTop: ".15rem",
                        marginBottom: ".15rem",
                      }}
                      placeholder="Ask your query"
                    />
                    <CButton
                      // type="submit"
                      onClick={() => {
                        handlePrompt();
                      }}
                      variant="ghost"
                      size="sm"
                      className="p-0 border-0 hover"
                      disabled={
                        inputVal == "" || loading || generatedData.length >= 20
                      }
                    >
                      {loading ? (
                        <CSpinner
                          size="sm"
                          className="m-2 "
                          style={{ color: "#086EB5" }}
                        />
                      ) : (
                        <CIcon
                          icon={cilSend}
                          className="m-2 hover text-success"
                          size="lg"
                        />
                      )}
                    </CButton>
                  </CCol>
                </CCard>
                <CButton
                  size="sm"
                  className="p-1"
                  onClick={() => {
                    setGeneratedData([]);
                  }}
                  color="success"
                  variant="outline"
                  style={{ marginTop: "14px" }}
                  disabled={loading}
                >
                  Clear
                </CButton>
              </CForm>
            </COffcanvasBody>
          </COffcanvas>
          {location === "Texas" ? (
            <CButton
              style={{
                position: "fixed",
                right: "40px",
                bottom: "30px",
                border: "1px solid rgb(139 123 192)",
                color: "#fff",
                background: "rgb(139 123 192)",
              }}
              className="p-1 d-flex align-items-center gap-2 justify-content-center px-2"
              onClick={() => {
                setVisible3(true);
              }}
            >
              <FaDatabase /> Text to SQL
            </CButton>
          ) : null}
        </CCol>
      </CRow>
    </div>
  );
};

export default TrendSearchSpotting;
