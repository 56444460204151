import {
  CButton,
  CCarousel,
  CCarouselCaption,
  CCarouselItem,
  CFormSelect,
  CImage,
  CSpinner,
} from "@coreui/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Navbar } from "../croma/components/Navbar";
import "./styles/trend.css";
import { IoClose } from "react-icons/io5";
import { RiZoomInFill, RiZoomOutFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";

const parentCategoryMapping = {
  Anklets: "Ornaments & Accessories",
  "Antique Jewelry": "Jewelry",
  "Diamond Jewelry": "Jewelry",
  "Gold Jewelry": "Jewelry",
  "Silver Jewelry": "Jewelry",
  Jewelry: "Jewelry",
  "Precious Metal": "Jewelry",
  "Precious & Semi-Precious Gems & Gemstone Jewelry": "Jewelry",
  "Birthstone Jewelry": "Jewelry",
  "Pearls & Pearl Jewelry": "Jewelry",
  "Engagement Rings": "Rings",
  "Wedding Rings": "Rings",
  Rings: "Rings",
  Bracelets: "Ornaments & Accessories",
  Earrings: "Ornaments & Accessories",
  Necklaces: "Ornaments & Accessories",
  "Precious Metal Jewelry": "Jewelry",
  Cufflinks: "Ornaments & Accessories",
  Hoodies: "Fashion",
  Pants: "Fashion",
  Shirts: "Fashion",
  Shorts: "Fashion",
  "Beds ": "Furniture",
  "Chairs ": "Furniture",
  "Sofas ": "Furniture",
  "Storage ": "Furniture",
  Tables: "Furniture",
};
const locationArr = ["Texas", "Florida", "California", "Illinois"];
const dateArr = [
  { start_dt: "2024-10-21", end_dt: "2024-10-28" },
  { start_dt: "2024-10-28", end_dt: "2024-11-04" },
  { start_dt: "2024-11-04", end_dt: "2024-11-11" },
];
const Trendspotting = () => {
  const { id } = useParams();
  console.log(id);
  const navigate = useNavigate();
  const [state, setState] = useState({
    trends: undefined,
    loadingTrends: false,
    isVisible: false,
    selectedImage: undefined,
    zoom: 1,
    position: { x: 0, y: 0 },
    isDragging: false,
    startPosition: { x: 0, y: 0 },
  });
  const [searchState, setSearchState] = useState({
    location: "",
    date: "",
  });
  const [trendingDataState, setTrendingDataState] = useState({
    data: undefined,
    loading: false,
  });
  const imageRef = useRef(null);
  const updateState = (updates) =>
    setState((prev) => ({ ...prev, ...updates }));
  const updateSearchState = (updates) =>
    setSearchState((prev) => ({ ...prev, ...updates }));
  const updateTrendingDataState = (updates) =>
    setTrendingDataState((prev) => ({ ...prev, ...updates }));
  useEffect(() => {
    getTrends();
  }, []);

  const getTrends = async () => {
    updateState({ trends: undefined, loadingTrends: true });

    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/YoungLA_overall_trend",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        Type: id === "fashion" ? "Clothing" : "Furniture",
      },
    };
    try {
      const response = await axios(config);
      console.log("trends resp", response);
      if (response != undefined) {
        updateState({ trends: response.data.data, loadingTrends: false });
      }
    } catch (error) {
      console.log(error);
      updateState({ trends: [], loadingTrends: false });
    }
  };

  const handleZoomIn = () =>
    updateState({ zoom: Math.min(state.zoom + 0.1, 3) });

  const handleZoomOut = () =>
    updateState({ zoom: Math.max(state.zoom - 0.1, 0.5) });

  const handleMouseDown = (e) => {
    e.preventDefault();
    updateState({
      isDragging: true,
      startPosition: {
        x: e.clientX - state.position.x,
        y: e.clientY - state.position.y,
      },
    });
  };

  const handleMouseMove = (e) => {
    if (state.isDragging) {
      updateState({
        position: {
          x: e.clientX - state.startPosition.x,
          y: e.clientY - state.startPosition.y,
        },
      });
    }
  };

  const handleMouseUp = () => {
    updateState({ isDragging: false });
  };

  const handleWheel = (e) => {
    e.preventDefault();
    const rect = imageRef.current?.getBoundingClientRect();
    if (!rect) return;

    // Calculate mouse position relative to image
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    // Calculate zoom
    const delta = e.deltaY > 0 ? 0.9 : 1.1;
    const newZoom = Math.max(0.5, Math.min(3, state.zoom * delta));

    // Calculate new position to zoom towards mouse
    const newPosition = {
      x: state.position.x - mouseX * (delta - 1),
      y: state.position.y - mouseY * (delta - 1),
    };

    updateState({ zoom: newZoom, position: newPosition });
  };

  const handleClose = () => {
    updateState({
      isVisible: false,
      zoom: 1,
      position: { x: 0, y: 0 },
      selectedImage: undefined,
    });
  };

  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    // console.log(day, month, year);
    return `${day} ${month} ${year}`;
  }

  const getTrendingData = async () => {
    updateTrendingDataState({
      data: undefined,
      loading: true,
    });
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/YoungLA_Subcategory",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": "AIzaSyAvm2m7VHRZb7GPEZvimSNa3-HU2GP8ZYY",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        location: searchState.location,
        current_date_time: searchState.date.end_dt,
        Type: id === "fashion" ? "Clothing" : "Furniture",
      },
    };
    try {
      let response = await axios(config);
      console.log("response", response);
      if (response !== undefined) {
        navigate(`/trendcraft/${id}/search-trends`, {
          state: {
            data: sortIntoParentCategories(response.data[0].data),
            searchState: searchState,
          },
        });
        updateTrendingDataState({
          data: sortIntoParentCategories(response.data[0].data),
          loading: false,
        });
      }
    } catch (error) {
      console.log("data error", error);
      updateTrendingDataState({
        loading: false,
        data: [],
      });
    }
  };

  function sortIntoParentCategories(data) {
    const parentCategories = {};

    data.forEach((subcategoryGroup) => {
      const originalCategoryName = subcategoryGroup.Category;
      const renamedCategoryName = renameCategory(originalCategoryName);
      const parentCategory = parentCategoryMapping[originalCategoryName];

      if (!parentCategories[parentCategory]) {
        parentCategories[parentCategory] = {};
      }

      if (!parentCategories[parentCategory][renamedCategoryName]) {
        parentCategories[parentCategory][renamedCategoryName] = [];
      }

      parentCategories[parentCategory][renamedCategoryName].push(
        subcategoryGroup
      );
    });

    // Sort each parent category, placing Miscellaneous categories last
    for (let parentCategory in parentCategories) {
      parentCategories[parentCategory] = Object.keys(
        parentCategories[parentCategory]
      )
        .sort((a, b) => {
          if (a.startsWith("Miscellaneous")) return 1;
          if (b.startsWith("Miscellaneous")) return -1;
          return a.localeCompare(b);
        })
        .reduce((sorted, key) => {
          sorted[key] = parentCategories[parentCategory][key];
          return sorted;
        }, {});
    }
    return parentCategories;
  }
  function renameCategory(categoryName) {
    if (categoryName === "Jewelry") {
      return "Miscellaneous Jewelry";
    }
    if (categoryName === "Rings") {
      return "Miscellaneous Rings";
    }
    return categoryName;
  }

  return (
    <>
      <Navbar />
      <div className="carousel-container">
        <CCarousel
          className="trend-carousel"
          controls
          indicators
          interval={5000}
        >
          {state.trends &&
            state.trends.map((item, index) => (
              <CCarouselItem
                key={index}
                className="h-100 trend-carousel-item position-relative"
              >
                <div
                  className="trend-carousel-bg"
                  style={{
                    backgroundImage: `url(${item.image_url})`,
                  }}
                ></div>
                <div className="trend-img-container p-1">
                  <CImage
                    onClick={() =>
                      updateState({ isVisible: true, selectedImage: item })
                    }
                    className="trend-img"
                    src={item.image_url}
                  />
                </div>

                <CCarouselCaption className="d-flex align-items-center trend-carousel-caption">
                  <div className="caption-title-container">
                    <div className="caption-bg"></div>
                    <h4 className="caption-title caption-stroked-title pt-2">
                      {item.Tagline}
                    </h4>
                  </div>
                </CCarouselCaption>
              </CCarouselItem>
            ))}
        </CCarousel>
        <div
          style={{
            position: "absolute",
            width: "50%",
            height: "32%",
            bottom: "4%",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 2001,
            backgroundColor: "rgb(0,0,0)" /* Fallback color */,
            backgroundColor: "rgba(0,0,0, 0.4)",
            filter: "blur(8px)",
          }}
        ></div>
        <div
          style={{
            width: "50%",
            height: "35%",
            position: "absolute",
            bottom: "3%",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 2001,
          }}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <div
            className="mb-2"
            style={{ color: "white", fontWeight: "400", fontSize: "20px" }}
          >
            Check Subcategories
          </div>
          <div className="d-flex align-items-center gap-2 mt-2 mb-2">
            <div
              style={{
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                width: "max-content",
                borderRadius: "40px",
              }}
              className="p-1 d-flex gap-3 bg-white px-2 "
            >
              <CFormSelect
                style={{
                  boxShadow: "none",
                  height: "30px",
                  width: "200px",
                  border: "none",
                  fontSize: "14px",
                }}
                value={searchState.location}
                onChange={(e) =>
                  updateSearchState({ location: e.target.value })
                }
                placeholder="Select Location"
                size="sm"
                className="p-1"
              >
                <option value="" disabled>
                  Select Location
                </option>
                {locationArr.map((loc) => {
                  return <option value={loc}>{loc}</option>;
                })}
              </CFormSelect>
            </div>
            <div
              style={{
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                width: "max-content",
                borderRadius: "40px",
              }}
              className="p-1 d-flex gap-3 bg-white px-2"
            >
              <CFormSelect
                style={{
                  boxShadow: "none",
                  height: "30px",
                  width: "200px",
                  border: "none",
                  fontSize: "14px",
                }}
                value={
                  searchState.date === ""
                    ? ""
                    : JSON.stringify(searchState.date)
                }
                onChange={(e) => {
                  updateSearchState({ date: JSON.parse(e.target.value) });
                }}
                placeholder="Select Location"
                size="sm"
                className="p-1"
              >
                <option value="" disabled>
                  Select Date Range
                </option>
                {dateArr.map((dt) => {
                  return (
                    <option value={JSON.stringify(dt)}>
                      {dt.start_dt !== "0"
                        ? `${formatDate(dt.start_dt)} to `
                        : "Till "}
                      {formatDate(dt.end_dt)}
                      {/* {JSON.stringify(dt)} */}
                    </option>
                  );
                })}
              </CFormSelect>
            </div>
          </div>
          <CButton
            onClick={() => getTrendingData()}
            variant="outline"
            className="px-5 py-1 mt-3 custom-btn d-flex align-items-center gap-2"
            disabled={searchState.location === "" && searchState.date === ""}
          >
            {trendingDataState.loading && <CSpinner size="sm" />}Search
          </CButton>
        </div>
      </div>

      {state.isVisible && (
        <div
          className="img-enlargable-container"
          style={{
            cursor: state.isDragging ? "grabbing" : "grab",
          }}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="zoom-btn-container">
            <RiZoomOutFill onClick={handleZoomOut} className="zoom-btn" />

            <RiZoomInFill onClick={handleZoomIn} className="zoom-btn" />
          </div>
          <div className="close-btn-container">
            <button onClick={handleClose} className="close-btn">
              <IoClose size={30} />
            </button>
          </div>
          {state.selectedImage && (
            <div
              ref={imageRef}
              className={"zoom-img-container"}
              style={{
                transform: `translate(${state.position.x}px, ${state.position.y}px) scale(${state.zoom})`,
                transition: state.isDragging ? "none" : "transform 0.2s",
                cursor: state.isDragging ? "grabbing" : "grab",
              }}
              onMouseDown={handleMouseDown}
              onWheel={handleWheel}
            >
              <img
                src={state.selectedImage.image_url}
                alt="Zoomed"
                className="zoom-img"
                draggable={false}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default Trendspotting;
