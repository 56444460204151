import { CAlert, CAvatar, CCard, CCol } from "@coreui/react";
import { FcDocument, FcSearch } from "react-icons/fc";
import { ChatMessage } from "./ChatMessage";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ImageGallery from "./ImageGallary";
import CIcon from "@coreui/icons-react";
import { cilWarning } from "@coreui/icons";
import aiImage from "../assets/icons/ai_image.png";
import aiAvatarImage from "../assets/icons/OIG.png";
const ImageChat = (props) => {
  console.log("props.generatedData", props.generatedData);
  return (
    <CCol
      style={{ overflow: "scroll" }}
      className="ps-1 pb-3 position-relative h-100"
    >
      <div>
        {props.generatedData.length == 0 ? (
          <>
            <div
              style={{
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="d-flex align-items-center justify-content-center pt-5"
            >
              <img src={aiImage} width={100} style={{ opacity: "0.6" }} />
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                gap: 25,
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
                width: "80%",
              }}
            >
              {/* <CCard
                style={{
                  zIndex: 1,
                }}
                className="p-2 px-3 scale-up-center"
              >
                <b className="d-flex gap-2 align-items-center">
                  <FcSearch size={"1.5rem"} /> Generate Images
                </b>
                <div className="mt-1 " style={{ color: "light" }}>
                  Ask a question in natural language on Policy Documents.
                </div>
              </CCard> */}
              <CCard
                style={{
                  zIndex: 1,
                }}
                className="p-2 px-3 scale-up-center"
              >
                <b className="d-flex gap-2 align-items-center">
                  <FcDocument size={"1.5rem"} />
                  Generate Images
                </b>
                {/* <div className="mt-1 " style={{ color: "grey" }}>
                  Get natural language summary from PDF Documents
                </div> */}
              </CCard>
            </div>
          </>
        ) : (
          <>
            {props.generatedData?.map((el, i) => {
              return (
                <div
                  ref={props.messageContainerRef}
                  style={{ paddingRight: "20px" }}
                  className="mb-4"
                >
                  <ChatMessage key={i + 1} text={el.question} isUser={true} />
                  {el.summary == "" ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      className="d-flex justify-content-start position-relative gap-2 align-items-start"
                    >
                      <CAvatar
                        style={{
                          width: "35px",
                          height: "35px",
                          overflow: "hidden",
                        }}
                        className="font-md mt-2 border avatar d-flex align-items-center justify-content-center"
                        size="sm"
                      >
                        <img
                          src={aiAvatarImage}
                          style={{
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                          }}
                        />
                      </CAvatar>
                      <CCol
                        className={` m-0 mt-1 align-items-center `}
                        style={{
                          maxWidth: "91%",
                          backgroundColor: "white",
                          borderRadius: "15px",
                          padding: "5px 10px",
                          marginBottom: "5px",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        }}
                      >
                        <SkeletonTheme
                          baseColor="#d4e8ffb8"
                          highlightColor="#73a9ff"
                        >
                          <h4>
                            <Skeleton count={1} width={"50%"} />
                          </h4>
                          <p>
                            <Skeleton height={12} width={"75%"} count={2} />
                          </p>
                        </SkeletonTheme>
                      </CCol>
                    </div>
                  ) : el.summary == "Empty" ? (
                    <div name={i}>
                      <ChatMessage
                        key={i}
                        text={
                          "Oops! No image is generated. Please refine your prompt and try again."
                        }
                        isUser={false}
                        isError={true}
                      />
                    </div>
                  ) : el.summary
                      ?.toLowerCase()
                      .includes(
                        "sorry couldn't find any information regarding that"
                      ) ||
                    el.summary?.toLowerCase().includes("answer not found") ? (
                    <div name={i}>
                      <ChatMessage
                        key={i}
                        text={
                          "We couldn't find any data matching your request. Please try asking in a different way or modify the criteria in the question."
                        }
                        isUser={false}
                        isError={true}
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        style={{ marginLeft: "10px" }}
                        className="d-flex justify-content-start position-relative gap-2 align-items-start"
                      >
                        <CAvatar
                          style={{
                            width: "35px",
                            height: "35px",
                            overflow: "hidden",
                          }}
                          className="font-md mt-2 border avatar d-flex align-items-center justify-content-center"
                          size="sm"
                        >
                          <img
                            src={aiAvatarImage}
                            style={{
                              width: "100%",
                              height: "100%",
                              overflow: "hidden",
                            }}
                          />
                        </CAvatar>
                        <div
                          className={`chat-message other mt-2 align-items-center font-md ms-0`}
                          style={{ width: "85%", maxWidth: "85%" }}
                        >
                          <ImageGallery
                            images={el.generated_image}
                            selectedImages={props.selectedImages}
                            setSelectedImages={props.setSelectedImages}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </>
        )}
        {props.generatedData.length >= 20 ? (
          <CAlert
            style={{
              bottom: 100,
              position: "fixed",
              // marginLeft: visible ? 450 : 300,
              zIndex: 11,
            }}
            color={"warning"}
            className="p-2 mb-2 font-md d-flex align-items-center gap-1 w-50"
          >
            <CIcon icon={cilWarning} size="sm" height={15} width={15} />
            <div>
              You have reached the chat limit of 20 questions. Please create New
              Chat chat to continue asking more questions.
            </div>
          </CAlert>
        ) : null}
      </div>
    </CCol>
  );
};
export default ImageChat;
