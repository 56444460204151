import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import useWebSocket from "./useWebSocket";
import axios from "axios";

const AudioTranscript = () => {
  const { connectionStatus, transcript, startRecording, stopRecording } =
    useWebSocket();
  const [results, setResults] = useState(null);
  const transcriptRef = useRef(null);

  console.log("connectionStatus", connectionStatus);
 
  const handleDownload = async (type) => {
    const urls = {
      original: "/download_original",
      beeped: "/download_beeped",
      process: "/process_audio",
    };

    try {
      const response = await axios.get(
        `https://audio-censor-301481222425.us-central1.run.app${urls[type]}`,
        { responseType: type === "process" ? "json" : "blob" }
      );

      if (type === "process") {
        console.log("Analysis Data:", response.data);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${type}_file.wav`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      <h1>Real-time Audio Processing</h1>

      <div className="controls" style={{ margin: "20px 0" }}>
        {/* <button onClick={handleStartRecording} disabled={isRecording}>
          Start Recording
        </button> */}
        {/* <button onClick={handleStopRecording} disabled={!isRecording}>
          Stop Recording
        </button> */}
        {/* <button
          onClick={handleDownloadOriginal}
          disabled={!downloadOriginalEnabled}
        >
          Download Original Audio
        </button> */}
        {/* <button onClick={handleProcessAudio} disabled={!processAudioEnabled}>
          Process Audio
        </button> */}
        {/* <button
          onClick={handleDownloadBeeped}
          disabled={!downloadBeepedEnabled}
        >
          Download Beeped Audio
        </button> */}
      </div>

      <div
        id="transcript"
        ref={transcriptRef}
        style={{
          height: "300px",
          border: "1px solid #ccc",
          padding: "10px",
          overflowY: "auto",
          margin: "20px 0",
        }}
      >
        <h3>Real-time Transcript</h3>
        {transcript.map((item, index) => (
          <p key={index} style={{ color: item.isFinal ? "black" : "gray" }}>
            {item.text}
          </p>
        ))}
      </div>

      {results && (
        <div className="results">
          <h3>Processing Results</h3>
          <div id="summary">
            <h4>Summary:</h4>
            <p>{results.summary}</p>
          </div>
          <div id="diarization">
            <h4>Diarization:</h4>
            <p dangerouslySetInnerHTML={{ __html: results.diarization }}></p>
          </div>
          <div id="sensitiveInfo">
            <h4>Sensitive Information:</h4>
            <pre>{JSON.stringify(results.sensitiveInfo, null, 2)}</pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioTranscript;
// ,
//     {
//       "id": 13,
//       "title": "Audio Transcript",
//       "icon": {
//         "component": "SiYourtraveldottv",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Identify new trends in furniture",
//       "route": "/audio-transcript",
//       "tags": ["trend", "shopping", "furniture"]
//     }