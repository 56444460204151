import { CCol } from "@coreui/react";
import { PiTextTBold } from "react-icons/pi";
import { FaBold } from "react-icons/fa";
import { FaItalic } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
const DefaultStyle = [
  {
    id: uuidv4(),
    text: "Add a heading",
    x: 50,
    y: 50,
    fontSize: 40,
    fontStyle: "normal",
    fontWeight: "bold",
    strikeThrough: "",
    underline: "",
    fontFamily: "Arial",
    fill: "black",
    width: 300,
    height: 50,
    isEditing: false,
    type: "text",
  },
  {
    id: uuidv4(),
    text: "Add a subheading",
    x: 50,
    y: 50,
    fontSize: 26,
    fontStyle: "normal",
    fontWeight: "bold",
    strikeThrough: "",
    underline: "",
    fontFamily: "Arial",
    fill: "black",
    width: 300,
    height: 40,
    isEditing: false,
    type: "text",
  },
  {
    id: uuidv4(),
    text: "Add a body text",
    x: 50,
    y: 50,
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "normal",
    strikeThrough: "",
    underline: "",
    fontFamily: "Arial",
    fill: "black",
    width: 300,
    height: 30,
    isEditing: false,
    type: "text",
  },
];

const TextToolbar = ({
  elements,
  updateElements,
  selectedElement,
  setSelectedElement,
}) => {
  const handleAddText = (textStyle) => {
    const newText = textStyle
      ? textStyle
      : {
          id: uuidv4(),
          text: "Sample Text",
          x: 50,
          y: 50,
          fontSize: 24,
          fontStyle: "normal",
          fontWeight: "normal",
          strikeThrough: "",
          underline: "",
          fontFamily: "Arial",

          fill: "black",
          width: "auto",
          height: "auto",
          isEditing: false,
          type: "text",
        };
    // props.setTexts([...props.texts, newText]);
    updateElements({ texts: [...elements.texts, newText] });
  };
  const handleStyleChange = (property, value) => {
    if (selectedElement.type === "text") {
      updateText(selectedElement.id, { [property]: value });
    }
  };

  const updateText = (id, newProps) => {
    console.log("newProps", newProps);
    // let updatedText = elements.texts.map((text) =>
    //   text.id === id ? { ...text, ...newProps } : text
    // );
    setSelectedElement({
      ...selectedElement,
      element: { ...selectedElement.element, ...newProps },
    });
    updateElements({
      texts: elements.texts.map((text) =>
        text.id === id ? { ...text, ...newProps } : text
      ),
    });
  };

  return (
    <CCol>
      <CCol
        style={{
          border: "1px solid lightgray",
          borderRadius: "5px",
          backgroundColor: "lightgray",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 2px -2px, rgba(129, 129, 129, 0.3) 0px 2px 4px -3px",
          cursor: "pointer",
          fontWeight: "500",
        }}
        onClick={() => handleAddText()}
        className="p-2 px-3 m-2 mx-3 mt-3 d-flex justify-content-between align-items-center"
      >
        Add Text <PiTextTBold size={20} />
      </CCol>
      <CCol className="p-2 px-3 m-2 d-flex flex-column">
        <CCol className="w-100 d-flex align-items-center justify-content-between p-0 py-1 side-tab-nested-content m-0 my-2">
          Default Text Style
        </CCol>
        {DefaultStyle.map((item, index) => (
          <CCol
            key={index}
            style={{
              border: "1px solid rgba(233, 233, 233, 0.3)",
              borderRadius: "5px",
              backgroundColor: "rgba(243, 243, 243, 0.3)",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 2px -2px, rgba(0, 0, 0, 0.3) 0px 2px 4px -3px",
              cursor: "pointer",
              fontWeight: `${index === 0 || index === 1 ? "700" : "400"}`,
              fontSize: `${
                index === 0 ? "22px" : index === 1 ? "16px" : "14px"
              }`,
            }}
            onClick={() => handleAddText(item)}
            className="p-2 m-2 mx-0 d-flex justify-content-between align-items-center"
          >
            {item.text}
          </CCol>
        ))}
        <CCol></CCol>
      </CCol>
    </CCol>
  );
};

export default TextToolbar;
