import React, { useState, useRef, useEffect } from "react";
import {
  Stage,
  Layer,
  Transformer,
  Group,
  Text,
  Image as KonvaImage,
  Rect,
} from "react-konva";
import { Html } from "react-konva-utils";
import { v4 as uuidv4 } from "uuid";
import useImage from "use-image";
import {
  CButton,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CInputGroup,
  CFormInput,
  CInputGroupText,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilActionRedo,
  cilActionUndo,
  cilTrash,
  cilZoomIn,
  cilZoomOut,
} from "@coreui/icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Konva from "konva";
import EditorFooter from "./EditorFooter";
import { MdDeleteForever } from "react-icons/md";
import { TiExport, TiMinus, TiPlus } from "react-icons/ti";
import EditorToolBar from "./EditorToolBar";
const MIN_ZOOM = 0.1;
const MAX_ZOOM = 3;

// Separate ImageElement component
const ImageElement = ({ image, isSelected, onSelect, onDelete }) => {
  const [img] = useImage(image.src);
  const imageRef = useRef();
  const groupRef = useRef();
  const calculateDimensions = (originalWidth, originalHeight) => {
    const maxWidth = 400; // Maximum width for the image
    const maxHeight = 300; // Maximum height for the image

    let newWidth = originalWidth;
    let newHeight = originalHeight;

    // Calculate aspect ratio
    const aspectRatio = originalWidth / originalHeight;

    // Resize if width exceeds maximum
    if (newWidth > maxWidth) {
      newWidth = maxWidth;
      newHeight = newWidth / aspectRatio;
    }

    // After adjusting width, check if height still exceeds maximum
    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = newHeight * aspectRatio;
    }

    return { width: newWidth, height: newHeight };
  };

  const getDimensions = () => {
    if (!img) return { width: 200, height: 200 }; // Default dimensions

    return calculateDimensions(img.width, img.height);
  };

  const dimensions = getDimensions();

  const handleSelect = (e) => {
    e.cancelBubble = true; // Prevent stage deselection
    onSelect();
  };

  return (
    <Group
      ref={groupRef}
      x={image.x}
      y={image.y}
      draggable
      onClick={handleSelect}
      onTap={handleSelect}
      id={image.id}
    >
      <KonvaImage
        ref={imageRef}
        image={img}
        width={dimensions.width}
        height={dimensions.height}
        onClick={handleSelect}
      />
    </Group>
  );
};

const OverlayImageEditor = ({
  elements,
  updateElements,
  selectedElement,
  setSelectedElement,
  resizeState,
  stageRef,
  innerStageSize,
  setInnerStageSize,
}) => {
  const [backgroundImage] = useImage(elements?.background?.imageUrl);
  const containerRef = useRef(null);
  const canvasContainerRef = useRef();
  const transformerRef = useRef();
  const [stageSize, setStageSize] = useState({ width: 1920, height: 1080 });
  const [isDragging, setIsDragging] = useState(false);
  const [scale, setScale] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(60);
  const [history, setHistory] = useState([]);
  const [currentStep, setCurrentStep] = useState(-1);
  const maxHistorySteps = 50;

  useEffect(() => {
    if (resizeState) {
      let newWidth, newHeight;
      if (!containerRef.current) return;
      const container = containerRef.current;
      const containerWidth = container.clientWidth - 16; // Account for margins
      const containerHeight = container.clientHeight - 16;
      const ratio = resizeState.value.width / resizeState.value.height;

      setInnerStageSize({ width: newWidth, height: newHeight });
      if (resizeState.unit === "pixel") {
        if (containerWidth / containerHeight > ratio) {
          newHeight = Math.min(containerHeight, resizeState.value.height);
          newWidth = newHeight * ratio;
        } else {
          newWidth = Math.min(containerWidth, resizeState.value.width);
          newHeight = newWidth / ratio;
        }
      } else if (resizeState.unit === "percentage") {
        const BASE_WIDTH = 1920;
        const BASE_HEIGHT = 1080;
        const tempWidth = (BASE_WIDTH * resizeState.value.width) / 100;
        const tempHeight = (BASE_HEIGHT * resizeState.value.height) / 100;
        const perRatio = tempWidth / tempHeight;
        if (containerWidth / containerHeight > perRatio) {
          newHeight = Math.min(containerHeight, tempHeight);
          newWidth = newHeight * perRatio;
        } else {
          newWidth = Math.min(containerWidth, tempWidth);
          newHeight = newWidth / perRatio;
        }
      }

      setInnerStageSize({ width: newWidth, height: newHeight });
    }
  }, [resizeState]);
  const saveToHistory = (newState) => {
    const newHistory = history.slice(0, currentStep + 1);
    newHistory.push(newState);

    // Limit history size
    if (newHistory.length > maxHistorySteps) {
      newHistory.shift();
    }

    setHistory(newHistory);
    setCurrentStep(newHistory.length - 1);
  };

  // Capture state changes
  useEffect(() => {
    const currentState = {
      images: elements.images,
      texts: elements.texts,
      selectedElement: selectedElement,
      backgroundImage: elements.background,
    };

    // Only save if state actually changed
    if (JSON.stringify(currentState) !== JSON.stringify(history[currentStep])) {
      saveToHistory(currentState);
    }
  }, [elements]);
  const handleUndo = () => {
    if (currentStep > 0) {
      const previousState = history[currentStep - 1];
      updateElements({
        images: previousState.images,
        texts: previousState.texts,
        background: previousState.background,
      });
      setSelectedElement(previousState.selectedElement);
      setCurrentStep(currentStep - 1);
      setCurrentStep(currentStep - 1);
    }
  };

  const handleRedo = () => {
    if (currentStep < history.length - 1) {
      const nextState = history[currentStep + 1];
      updateElements({
        images: nextState.images,
        texts: nextState.texts,
        background: nextState.background,
      });
      setSelectedElement(nextState.selectedElement);
      setCurrentStep(currentStep + 1);
    }
  };

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "z") {
        e.preventDefault();
        if (e.shiftKey) {
          handleRedo();
        } else {
          handleUndo();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [currentStep, history]);
  useEffect(() => {
    const updateStageSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        setStageSize((prev) => ({ ...prev, width: containerWidth }));
      }
    };

    updateStageSize();

    // Update size on window resize
    window.addEventListener("resize", updateStageSize);
    return () => window.removeEventListener("resize", updateStageSize);
  }, []);

  const handleWheel = (e) => {
    e.preventDefault();

    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    // Calculate new scale with finer control
    const zoomFactor = e.deltaY > 0 ? 0.95 : 1.05;
    const newScale = Math.min(
      MAX_ZOOM,
      Math.max(MIN_ZOOM, oldScale * zoomFactor)
    );

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();

    setScale(newScale);
    setZoomLevel(Math.round(newScale * 100));
  };

  const handleManualZoom = (newScale) => {
    if (newScale < 0.1 || newScale > 3) return;

    const stage = stageRef.current;
    const oldScale = stage.scaleX();

    const center = {
      x: stage.width() / 2,
      y: stage.height() / 2,
    };

    const mousePointTo = {
      x: (center.x - stage.x()) / oldScale,
      y: (center.y - stage.y()) / oldScale,
    };

    const newPos = {
      x: center.x - mousePointTo.x * newScale,
      y: center.y - mousePointTo.y * newScale,
    };

    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();

    setScale(newScale);
    setZoomLevel(Math.round(newScale * 100));
  };

  const handleZoomInput = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 10 && value <= 300) {
      const newScale = value / 100;
      handleManualZoom(newScale);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("wheel", handleWheel);
    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, []);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          const { width, height } = entry.contentRect;
          setStageSize({ width, height });
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleSelect = (type, id, element) => {
    setSelectedElement({ id, type, element });
  };

  useEffect(() => {
    const stage = stageRef.current;
    const transformer = transformerRef.current;

    if (transformer) {
      const selectedNode = selectedElement.id
        ? stage.findOne(`#${selectedElement.id}`)
        : null;

      transformer.nodes(selectedNode ? [selectedNode] : []);
      transformer.getLayer()?.batchDraw();
    }
  }, [selectedElement, elements]);

  const onRemove = () => {
    if (selectedElement.type === "text") {
      updateElements({
        texts: elements.texts.filter((t) => t.id !== selectedElement.id),
      });
    } else if (selectedElement.type === "image") {
      updateElements({
        images: elements.images.filter((i) => i.id !== selectedElement.id),
      });
    } else if (selectedElement.type === "background") {
      updateElements({
        background: { imageUrl: "", id: null },
      });
    }
    setSelectedElement({ id: "", type: "", element: null });
  };

  const handleExportForGeneration = () => {
    setSelectedElement({ id: "", type: "", element: null });
    if (!stageRef.current) return;
    const stage = stageRef.current;
    const group = stage.findOne("Group");

    if (!group) return;

    try {
      // Store current stage state
      const currentScale = stage.scaleX();
      const currentPosition = { x: stage.x(), y: stage.y() };
      const rect = group.findOne("Rect");

      // Store original rect properties
      const originalRectProps = {
        fill: rect?.fill(),
        shadowColor: rect?.shadowColor(),
        shadowBlur: rect?.shadowBlur(),
        shadowOffsetX: rect?.shadowOffsetX(),
        shadowOffsetY: rect?.shadowOffsetY(),
        cornerRadius: rect?.cornerRadius(),
      };

      // Reset stage and rect properties
      if (rect) {
        rect.shadowColor("transparent");
        rect.shadowBlur(0);
        rect.shadowOffsetX(0);
        rect.shadowOffsetY(0);
        rect.cornerRadius(0);
        rect.fill("white"); // Set white background
      }

      stage.scale({ x: 1, y: 1 });
      stage.position({ x: 0, y: 0 });

      // Use resizeState dimensions
      const width = resizeState.value.width;
      const height = resizeState.value.height;

      const scaleX = width / innerStageSize.width;
      const scaleY = height / innerStageSize.height;
      stage.scale({ x: scaleX, y: scaleY });
      // Apply clip to match exact dimensions
      group.clip({
        x: 0,
        y: 0,
        width: width,
        height: height,
      });

      // Export with exact dimensions
      const dataUrl = group.toDataURL({
        pixelRatio: 1,
        width: width,
        height: height,
        mimeType: "image/png",
      });

      // Convert base64 to file
      const binaryData = atob(dataUrl.split(",")[1]);
      const array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        array[i] = binaryData.charCodeAt(i);
      }
      const file = new File([array], "overlay.png", { type: "image/png" });

      // Restore original stage state
      stage.scale({ x: currentScale, y: currentScale });
      stage.position(currentPosition);
      group.clip(null);

      if (rect) {
        Object.entries(originalRectProps).forEach(([prop, value]) => {
          if (value !== undefined) rect[prop](value);
        });
      }

      return file;
    } catch (error) {
      console.error("Export error:", error);
      return null;
    }
  };

  // Handle custom size change

  const handleStageClick = (e) => {
    // Get the clicked target and the stage
    const clickedOnStage = e.target === e.target.getStage();
    const clickedOnBackground = e.target.attrs.id === "background-image";

    // If clicked on empty stage or background, deselect
    if (clickedOnStage || clickedOnBackground) {
      setSelectedElement({ id: "", type: "", element: null });
    }
  };
  // Function to handle background image upload

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "92%",
        position: "relative",
        padding: "0px",
        marginBottom: "40px",
      }}
    >
      {/* <EditorToolBar
        handleExport={handleExport}
        selectedElement={selectedElement}
        onRemove={onRemove}
      /> */}

      <CCol
        ref={canvasContainerRef}
        className="m-0 my-1"
        style={{ border: "1px solid lightgray" }}
      >
        <Stage
          ref={stageRef}
          width={stageSize.width}
          height={stageSize.height}
          draggable
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
          onClick={handleStageClick}
          scale={{ x: scale, y: scale }}
          style={{
            backgroundColor: "rgb(240,241,245)",
          }}
        >
          <Layer
            x={(stageSize.width - innerStageSize.width) / 2}
            y={(stageSize.height - innerStageSize.height) / 2}
            clip={{
              x: 0,
              y: 0,
              width: innerStageSize.width,
              height: innerStageSize.height,
            }}
          >
            <Group>
              {/* Inner stage background */}
              <Rect
                width={innerStageSize.width}
                height={innerStageSize.height}
                fill={backgroundImage ? "transparent" : "white"}
                shadowColor="rgba(0, 0, 0, 0.36)"
                shadowBlur={10}
                shadowOffsetX={5}
                shadowOffsetY={5}
                cornerRadius={5}
              />

              {/* Background image if present */}

              {elements.images.map((image) => (
                <ImageElement
                  key={image.id}
                  image={image}
                  isSelected={image.id === selectedElement.id}
                  onSelect={() => handleSelect("image", image.id, image)}
                  onDelete={() => {
                    updateElements({
                      images: elements.images.filter(
                        (img) => img.id !== image.id
                      ),
                    });
                    setSelectedElement(null);
                  }}
                />
              ))}

              <Transformer
                ref={transformerRef}
                borderStroke="#0084ff"
                borderStrokeWidth={1}
                anchorStroke="#0084ff"
                anchorFill="#fff"
                anchorSize={8}
                anchorCornerRadius={50}
                padding={5}
                rotateAnchorOffset={30}
                enabledAnchors={[
                  "top-left",
                  "top-right",
                  "bottom-left",
                  "bottom-right",
                ]}
                borderDash={[4, 4]}
                rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
                boundBoxFunc={(oldBox, newBox) => {
                  newBox.width = Math.max(30, newBox.width);
                  newBox.height = Math.max(30, newBox.height);
                  return newBox;
                }}
              />
            </Group>
          </Layer>
        </Stage>
      </CCol>
      <EditorFooter
        handleUndo={handleUndo}
        handleRedo={handleRedo}
        currentStep={currentStep}
        history={history}
        scale={scale}
        zoomLevel={zoomLevel}
        handleZoomInput={handleZoomInput}
        handleManualZoom={handleManualZoom}
      />
    </div>
  );
};

export default OverlayImageEditor;
