import React, { useState, useRef, useEffect } from "react";
import {
  Stage,
  Layer,
  Transformer,
  Group,
  Text,
  Image as KonvaImage,
  Rect,
} from "react-konva";
import { Html } from "react-konva-utils";
import { v4 as uuidv4 } from "uuid";
import useImage from "use-image";
import {
  CButton,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CInputGroup,
  CFormInput,
  CInputGroupText,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilActionRedo,
  cilActionUndo,
  cilTrash,
  cilZoomIn,
  cilZoomOut,
} from "@coreui/icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Konva from "konva";
import EditorFooter from "./EditorFooter";
import { MdDeleteForever } from "react-icons/md";
import { TiExport, TiMinus, TiPlus } from "react-icons/ti";
import EditorToolBar from "./EditorToolBar";
const MIN_ZOOM = 0.1;
const MAX_ZOOM = 3;
const BackgroundImage = ({ image, size, onSelect }) => {
  const imageRef = useRef();

  const handleSelect = (e) => {
    e.cancelBubble = true;
    onSelect();
  };

  return (
    <KonvaImage
      ref={imageRef}
      image={image}
      width={size.width}
      height={size.height}
      onClick={handleSelect}
      id="background-image"
    />
  );
};
// Separate ImageElement component
const ImageElement = ({ image, isSelected, onSelect, onDelete }) => {
  const [img] = useImage(image.src);
  const imageRef = useRef();
  const groupRef = useRef();
  const calculateDimensions = (originalWidth, originalHeight) => {
    const maxWidth = 400; // Maximum width for the image
    const maxHeight = 300; // Maximum height for the image

    let newWidth = originalWidth;
    let newHeight = originalHeight;

    // Calculate aspect ratio
    const aspectRatio = originalWidth / originalHeight;

    // Resize if width exceeds maximum
    if (newWidth > maxWidth) {
      newWidth = maxWidth;
      newHeight = newWidth / aspectRatio;
    }

    // After adjusting width, check if height still exceeds maximum
    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = newHeight * aspectRatio;
    }

    return { width: newWidth, height: newHeight };
  };

  const getDimensions = () => {
    if (!img) return { width: 200, height: 200 }; // Default dimensions

    return calculateDimensions(img.width, img.height);
  };

  const dimensions = getDimensions();

  const handleSelect = (e) => {
    e.cancelBubble = true; // Prevent stage deselection
    onSelect();
  };

  return (
    <Group
      ref={groupRef}
      x={image.x}
      y={image.y}
      draggable
      onClick={handleSelect}
      onTap={handleSelect}
      id={image.id}
    >
      <KonvaImage
        ref={imageRef}
        image={img}
        width={dimensions.width}
        height={dimensions.height}
        onClick={handleSelect}
      />
    </Group>
  );
};

const TextElement = ({
  textProps,
  isSelected,
  onSelect,
  onChange,
  onRemove,
  isEditing,
  onStartEdit,
  onFinishEdit,
}) => {
  const textRef = useRef();
  const groupRef = useRef();
  const [editableText, setEditableText] = useState(textProps.text);

  const handleSelect = (e) => {
    e.cancelBubble = true;
    onSelect();
  };

  const handleDblClick = (e) => {
    e.cancelBubble = true;
    onStartEdit();
  };

  const handleTextChange = (e) => {
    setEditableText(e.target.value);
  };

  const handleBlur = () => {
    onChange({ text: editableText });
    onFinishEdit();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };
  const getFontStyle = () => {
    return {
      fontStyle: `${textProps.fontStyle || "normal"} ${
        textProps.fontWeight || "normal"
      }`,
      fontDecoration: `${textProps.strikeThrough} ${textProps.underline}`,
    };
  };

  const fontStyles = getFontStyle();
  console.log("textprops", textProps);
  return (
    <Group
      ref={groupRef}
      draggable
      x={textProps.x}
      y={textProps.y}
      rotation={textProps.rotation}
      scaleX={textProps.scaleX}
      scaleY={textProps.scaleY}
      id={textProps.id}
      onClick={handleSelect}
      onTap={handleSelect}
      onDblClick={handleDblClick}
      onDblTap={handleDblClick}
      onDragMove={(e) => {
        const node = e.target;
        onChange({
          x: node.x(),
          y: node.y(),
        });
      }}
      onTransform={(e) => {
        const node = groupRef.current;
        onChange({
          x: node.x(),
          y: node.y(),
          rotation: node.rotation(),
          scaleX: node.scaleX(),
          scaleY: node.scaleY(),
        });
      }}
    >
      {isEditing ? (
        <Html>
          <input
            type="text"
            value={editableText}
            onChange={handleTextChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              fontSize: `${textProps.fontSize}px`,
              fontFamily: textProps.fontFamily,
              fontStyle: textProps.fontStyle,
              fontWeight: textProps.fontWeight,
              color: textProps.fill,
              border: "none",
              padding: "0px",
              margin: "0px",
              background: "transparent",
              outline: "1px solid blue",
              width: `${Math.max(textProps.width, 50)}px`,
            }}
            autoFocus
          />
        </Html>
      ) : (
        <Text
          ref={textRef}
          text={textProps.text}
          fontSize={textProps.fontSize}
          fontFamily={textProps.fontFamily}
          fill={textProps.fill}
          width={textProps.width}
          fontStyle={fontStyles.fontStyle}
          height={textProps.height}
          align={textProps.align}
          textDecoration={fontStyles.fontDecoration}
          wrap="word"
          lineHeight={1.2}
        />
      )}
    </Group>
  );
};

const ImageEditor = ({
  elements,
  updateElements,
  selectedElement,
  setSelectedElement,
}) => {
  const [backgroundImage] = useImage(elements?.background?.imageUrl);
  const containerRef = useRef(null);
  const canvasContainerRef = useRef();
  const stageRef = useRef();
  const transformerRef = useRef();
  const bgRef = useRef();
  const [stageSize, setStageSize] = useState({ width: 600, height: 600 });
  const [innerStageSize, setInnerStageSize] = useState({
    width: 600,
    height: 600,
  });
  const [editingText, setEditingText] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [scale, setScale] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(70);
  const [history, setHistory] = useState([]);
  const [currentStep, setCurrentStep] = useState(-1);
  const [customSize, setCustomSize] = useState({ width: 600, height: 600 });
  const [showSizeModal, setShowSizeModal] = useState(false);
  const maxHistorySteps = 50;

  const saveToHistory = (newState) => {
    const newHistory = history.slice(0, currentStep + 1);
    newHistory.push(newState);

    // Limit history size
    if (newHistory.length > maxHistorySteps) {
      newHistory.shift();
    }

    setHistory(newHistory);
    setCurrentStep(newHistory.length - 1);
  };

  // Capture state changes
  useEffect(() => {
    const currentState = {
      images: elements.images,
      texts: elements.texts,
      selectedElement: selectedElement,
      backgroundImage: elements.background,
    };

    // Only save if state actually changed
    if (JSON.stringify(currentState) !== JSON.stringify(history[currentStep])) {
      saveToHistory(currentState);
    }
  }, [elements]);
  const handleUndo = () => {
    if (currentStep > 0) {
      const previousState = history[currentStep - 1];
      updateElements({
        images: previousState.images,
        texts: previousState.texts,
        background: previousState.background,
      });
      setSelectedElement(previousState.selectedElement);
      setCurrentStep(currentStep - 1);
      setCurrentStep(currentStep - 1);
    }
  };

  const handleRedo = () => {
    if (currentStep < history.length - 1) {
      const nextState = history[currentStep + 1];
      updateElements({
        images: nextState.images,
        texts: nextState.texts,
        background: nextState.background,
      });
      setSelectedElement(nextState.selectedElement);
      setCurrentStep(currentStep + 1);
    }
  };

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "z") {
        e.preventDefault();
        if (e.shiftKey) {
          handleRedo();
        } else {
          handleUndo();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [currentStep, history]);
  useEffect(() => {
    const updateStageSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        setStageSize((prev) => ({ ...prev, width: containerWidth }));
      }
    };

    updateStageSize();

    // Update size on window resize
    window.addEventListener("resize", updateStageSize);
    return () => window.removeEventListener("resize", updateStageSize);
  }, []);

  useEffect(() => {
    const updateStageDimensions = () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const containerWidth = container.clientWidth - 16; // Account for margins
      const containerHeight = container.clientHeight - 16;

      if (backgroundImage) {
        // If there's a background image, maintain its aspect ratio
        const imageRatio = backgroundImage.width / backgroundImage.height;
        let newWidth, newHeight;

        if (containerWidth / containerHeight > imageRatio) {
          // Container is wider than image ratio
          newHeight = Math.min(containerHeight, backgroundImage.height);
          newWidth = newHeight * imageRatio;
        } else {
          // Container is taller than image ratio
          newWidth = Math.min(containerWidth, backgroundImage.width);
          newHeight = newWidth / imageRatio;
        }

        setInnerStageSize({ width: newWidth, height: newHeight });
      } else {
        // No background image - use 1:1 ratio
        const size = Math.min(containerWidth, containerHeight);
        setInnerStageSize({ width: size, height: size });
      }
    };

    // Initial update
    updateStageDimensions();

    // Create resize observer
    const resizeObserver = new ResizeObserver(updateStageDimensions);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [backgroundImage]);

  const handleWheel = (e) => {
    e.preventDefault();

    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    // Calculate new scale with finer control
    const zoomFactor = e.deltaY > 0 ? 0.95 : 1.05;
    const newScale = Math.min(
      MAX_ZOOM,
      Math.max(MIN_ZOOM, oldScale * zoomFactor)
    );

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();

    setScale(newScale);
    setZoomLevel(Math.round(newScale * 100));
  };

  const handleManualZoom = (newScale) => {
    if (newScale < 0.1 || newScale > 3) return;

    const stage = stageRef.current;
    const oldScale = stage.scaleX();

    const center = {
      x: stage.width() / 2,
      y: stage.height() / 2,
    };

    const mousePointTo = {
      x: (center.x - stage.x()) / oldScale,
      y: (center.y - stage.y()) / oldScale,
    };

    const newPos = {
      x: center.x - mousePointTo.x * newScale,
      y: center.y - mousePointTo.y * newScale,
    };

    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();

    setScale(newScale);
    setZoomLevel(Math.round(newScale * 100));
  };

  // Handle manual zoom input
  const handleZoomInput = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 10 && value <= 300) {
      const newScale = value / 100;
      handleManualZoom(newScale);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("wheel", handleWheel);
    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, []);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          const { width, height } = entry.contentRect;
          setStageSize({ width, height });
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleSelect = (type, id, element) => {
    setSelectedElement({ id, type, element });
  };

  const handleExport = (format) => {
    setSelectedElement({ id: "", type: "", element: null });
    if (!stageRef.current || !backgroundImage) return;
    const stage = stageRef.current;
    const group = stage.findOne("Group");
    if (!group) {
      console.error("Group not found");
      return;
    }

    try {
      // Store current stage state
      const currentScale = stage.scaleX();
      const currentPosition = {
        x: stage.x(),
        y: stage.y(),
      };
      const rect = group.findOne("Rect");
      const originalRectProps = {};
      if (rect) {
        originalRectProps.fill = rect.fill();
        originalRectProps.shadowColor = rect.shadowColor();
        originalRectProps.shadowBlur = rect.shadowBlur();
        originalRectProps.shadowOffsetX = rect.shadowOffsetX();
        originalRectProps.shadowOffsetY = rect.shadowOffsetY();
        originalRectProps.cornerRadius = rect.cornerRadius();

        // Set clean properties for export
        rect.shadowColor("transparent");
        rect.shadowBlur(0);
        rect.shadowOffsetX(0);
        rect.shadowOffsetY(0);
        rect.cornerRadius(0);
        rect.fill("transparent");
      }

      // Reset stage transformation temporarily
      stage.scale({ x: 1, y: 1 });
      stage.position({ x: 0, y: 0 });

      // Get background image dimensions
      const bgWidth = backgroundImage.width;
      const bgHeight = backgroundImage.height;

      // Calculate scale to match background image size
      const scaleX = bgWidth / innerStageSize.width;
      const scaleY = bgHeight / innerStageSize.height;

      // Apply the scale to match background image dimensions
      stage.scale({ x: scaleX, y: scaleY });

      // Set clip to match inner stage size
      group.clip({
        x: 0,
        y: 0,
        width: innerStageSize.width,
        height: innerStageSize.height,
      });

      // Export with exact background image dimensions
      const dataUrl = group.toDataURL({
        pixelRatio: 1,
        width: bgWidth,
        height: bgHeight,
        mimeType: format === "jpg" ? "image/jpeg" : "image/png",
      });

      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");

      if (format === "pdf") {
        const pdf = new jsPDF({
          orientation: bgWidth > bgHeight ? "landscape" : "portrait",
          unit: "px",
          format: [bgWidth, bgHeight],
        });
        pdf.addImage(dataUrl, "PNG", 0, 0, bgWidth, bgHeight);
        pdf.save(`image-export-${timestamp}.pdf`);
      } else {
        const link = document.createElement("a");
        link.download = `image-export-${timestamp}.${format}`;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      // Restore original stage state
      stage.scale({ x: currentScale, y: currentScale });
      stage.position(currentPosition);
      group.clip(null);
      if (rect) {
        rect.fill(originalRectProps.fill);
        rect.shadowColor(originalRectProps.shadowColor);
        rect.shadowBlur(originalRectProps.shadowBlur);
        rect.shadowOffsetX(originalRectProps.shadowOffsetX);
        rect.shadowOffsetY(originalRectProps.shadowOffsetY);
        rect.cornerRadius(originalRectProps.cornerRadius);
      }
    } catch (error) {
      console.error("Export error:", error);
      alert("An error occurred while exporting the image.");
    }
  };

  useEffect(() => {
    const stage = stageRef.current;
    const transformer = transformerRef.current;

    if (transformer) {
      const selectedNode = selectedElement.id
        ? stage.findOne(`#${selectedElement.id}`)
        : null;

      transformer.nodes(selectedNode ? [selectedNode] : []);
      transformer.getLayer()?.batchDraw();
    }
  }, [selectedElement, elements]);

  const onRemove = () => {
    if (selectedElement.type === "text") {
      updateElements({
        texts: elements.texts.filter((t) => t.id !== selectedElement.id),
      });
    } else if (selectedElement.type === "image") {
      updateElements({
        images: elements.images.filter((i) => i.id !== selectedElement.id),
      });
    } else if (selectedElement.type === "background") {
      updateElements({
        background: { imageUrl: "", id: null },
      });
    }
    setSelectedElement({ id: "", type: "", element: null });
  };
  const handleStyleChange = (property, value) => {
    if (selectedElement.type === "text") {
      updateText(selectedElement.id, { [property]: value });
    }
  };

  const updateText = (id, newProps) => {
    setSelectedElement({
      ...selectedElement,
      element: { ...selectedElement.element, ...newProps },
    });
    updateElements({
      texts: elements.texts.map((text) =>
        text.id === id ? { ...text, ...newProps } : text
      ),
    });
  };

  // Handle background selection
  const handleBackgroundSelect = () => {
    setSelectedElement({
      id: "background",
      type: "background",
      element: elements.background,
    });
  };

  // Handle custom size change
  const handleSizeChange = (newSize) => {
    setCustomSize(newSize);
    setInnerStageSize(newSize);

    // Update background if exists
    if (elements.background) {
      updateElements({
        ...elements,
        background: {
          ...elements.background,
          width: newSize.width,
          height: newSize.height,
        },
      });
    }
  };
  const handleStageClick = (e) => {
    // Get the clicked target and the stage
    const clickedOnStage = e.target === e.target.getStage();
    const clickedOnBackground = e.target.attrs.id === "background-image";

    // If clicked on empty stage or background, deselect
    if (clickedOnStage || clickedOnBackground) {
      setSelectedElement({ id: "", type: "", element: null });
    }
  };
  // Function to handle background image upload
  const handleBackgroundUpload = (imageUrl) => {
    updateElements({
      ...elements,
      background: {
        imageUrl,
        width: innerStageSize.width,
        height: innerStageSize.height,
      },
    });
  };
  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "92%",
        position: "relative",
        padding: "0px",
        marginBottom: "40px",
      }}
    >
      <EditorToolBar
        handleExport={handleExport}
        selectedElement={selectedElement}
        onRemove={onRemove}
        handleStyleChange={handleStyleChange}
      />

      {showSizeModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Custom Size</h3>
            <input
              type="number"
              value={customSize.width}
              onChange={(e) =>
                setCustomSize((prev) => ({
                  ...prev,
                  width: parseInt(e.target.value),
                }))
              }
            />
            <input
              type="number"
              value={customSize.height}
              onChange={(e) =>
                setCustomSize((prev) => ({
                  ...prev,
                  height: parseInt(e.target.value),
                }))
              }
            />
            <button
              onClick={() => {
                handleSizeChange(customSize);
                setShowSizeModal(false);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
      <CCol
        ref={canvasContainerRef}
        className="m-0 my-1"
        style={{ border: "1px solid lightgray" }}
      >
        <Stage
          ref={stageRef}
          width={stageSize.width}
          height={stageSize.height}
          draggable
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
          onClick={handleStageClick}
          scale={{ x: scale, y: scale }}
          style={{
            backgroundColor: "rgb(240,241,245)",
          }}
        >
          <Layer
            x={(stageSize.width - innerStageSize.width) / 2}
            y={(stageSize.height - innerStageSize.height) / 2}
            clip={{
              x: 0,
              y: 0,
              width: innerStageSize.width,
              height: innerStageSize.height,
            }}
          >
            <Group>
              {/* Inner stage background */}
              <Rect
                width={innerStageSize.width}
                height={innerStageSize.height}
                fill={backgroundImage ? "transparent" : "white"}
                shadowColor="rgba(0, 0, 0, 0.36)"
                shadowBlur={10}
                shadowOffsetX={5}
                shadowOffsetY={5}
                cornerRadius={5}
              />

              {/* Background image if present */}
              {backgroundImage && (
                <BackgroundImage
                  image={backgroundImage}
                  size={innerStageSize}
                  onSelect={handleBackgroundSelect}
                />
              )}

              {elements.images.map((image) => (
                <ImageElement
                  key={image.id}
                  image={image}
                  isSelected={image.id === selectedElement.id}
                  onSelect={() => handleSelect("image", image.id, image)}
                  onDelete={() => {
                    updateElements({
                      images: elements.images.filter(
                        (img) => img.id !== image.id
                      ),
                    });
                    setSelectedElement(null);
                  }}
                />
              ))}

              {elements.texts.map((text) => (
                <TextElement
                  key={text.id}
                  textProps={text}
                  isSelected={text.id === selectedElement.id}
                  onSelect={() => handleSelect("text", text.id, text)}
                  onChange={(newProps) => {
                    updateElements({
                      texts: elements.texts.map((t) =>
                        t.id === text.id ? { ...t, ...newProps } : t
                      ),
                    });
                  }}
                  isEditing={editingText === text.id}
                  onStartEdit={() => setEditingText(text.id)}
                  onFinishEdit={() => setEditingText(null)}
                />
              ))}

              <Transformer
                ref={transformerRef}
                borderStroke="#0084ff"
                borderStrokeWidth={1}
                anchorStroke="#0084ff"
                anchorFill="#fff"
                anchorSize={8}
                anchorCornerRadius={50}
                padding={5}
                rotateAnchorOffset={30}
                enabledAnchors={[
                  "top-left",
                  "top-right",
                  "bottom-left",
                  "bottom-right",
                ]}
                borderDash={[4, 4]}
                rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
                boundBoxFunc={(oldBox, newBox) => {
                  newBox.width = Math.max(30, newBox.width);
                  newBox.height = Math.max(30, newBox.height);
                  return newBox;
                }}
              />
            </Group>
          </Layer>
        </Stage>
      </CCol>
      <EditorFooter
        handleUndo={handleUndo}
        handleRedo={handleRedo}
        currentStep={currentStep}
        history={history}
        scale={scale}
        zoomLevel={zoomLevel}
        handleZoomInput={handleZoomInput}
        handleManualZoom={handleManualZoom}
      />
    </div>
  );
};

export default ImageEditor;
