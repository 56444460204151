import {
  CTab,
  CTabContent,
  CTabList,
  CTabPanel,
  CTabs,
  CAlert,
  CCol,
  CCard,
  CButton,
  CTooltip,
  CSpinner,
} from "@coreui/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useEffect, useRef, useState } from "react";
import { FaFileImage } from "react-icons/fa";
import { IoMdCloudDownload, IoMdCloudUpload } from "react-icons/io";
import { FaSave } from "react-icons/fa";

import axios from "axios";
import { IoClose } from "react-icons/io5";
import { RiZoomInFill, RiZoomOutFill } from "react-icons/ri";
import { IoArrowBackSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";

const OverlayChat = ({
  formState,
  updateFormState,
  handleEdit,
  overlayGeneratedState,
  updateGeneratorState,
  setOverlayEditorVisible,
  updateGeneratePromptState,
}) => {
  const { id } = useParams();
  const tabs =
    id === "fashion"
      ? [
          { name: "Fashion", id: "fashion" },
          { name: "Logos", id: "logo_images/fashion_logo" },
        ]
      : id === "furniture"
      ? [
          { name: "Furniture", id: "furniture" },
          { name: "Logos", id: "logo_images/furniture_logo" },
        ]
      : [
          { name: "Fashion", id: "fashion" },
          { name: "Furniture", id: "furniture" },
          { name: "Logos", id: "logo_images" },
        ];

  const fileInputRef = useRef(null);
  const genImageRef = useRef(null);
  const [zoomImageState, setZoomImageState] = useState({
    isVisible: false,
    selectedImage: undefined,
    zoom: 1,
    position: { x: 0, y: 0 },
    isDragging: false,
    startPosition: { x: 0, y: 0 },
  });

  const [imageDataState, setImageDataState] = useState({
    imageData: [],
    loading: false,
  });
  const [uploadLoader, setUploadLoader] = useState(false);
  const updateZoomImageState = (updates) =>
    setZoomImageState((prev) => ({ ...prev, ...updates }));
  const updataImageDataState = (updates) =>
    setImageDataState((prev) => ({ ...prev, ...updates }));
  const handleAreaClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        updateFormState({ selectedImage: e.target.result }); // Set the image data URL
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // console.log("e.target.reuslt", e.target.result);
        updateFormState({ selectedImage: e.target.result }); // Set the image data URL
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please drop a valid image file.");
    }
  };

  async function convertUrlsToBase64(inputArray) {
    const fetchBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    };

    const convertedArray = await Promise.all(
      inputArray.map(async (item) => {
        const base64Url = await fetchBase64(item.url);
        return {
          ...item,
          url: base64Url,
        };
      })
    );

    return convertedArray;
  }

  const getImageData = async (type) => {
    updataImageDataState({
      imageData: [],
      loading: true,
    });
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_signed_url",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        bucket_name: "croma_images",
        folder_name: type,
      },
    };

    try {
      // let response = tempResp;
      let response = await axios(config);
      // console.log("response get", response);
      if (response != undefined) {
        let base64Arr = await convertUrlsToBase64(response.data.signed_urls);
        // console.log("base64Arr", base64Arr);
        updataImageDataState({
          imageData: base64Arr,
          loading: false,
        });
      }
    } catch (error) {
      console.log("data error", error);
      updataImageDataState({
        loading: false,
      });
    }
  };
  const handleZoomIn = () =>
    updateZoomImageState({ zoom: Math.min(zoomImageState.zoom + 0.1, 3) });

  const handleZoomOut = () =>
    updateZoomImageState({ zoom: Math.max(zoomImageState.zoom - 0.1, 0.5) });

  const handleMouseDown = (e) => {
    e.preventDefault();
    updateZoomImageState({
      isDragging: true,
      startPosition: {
        x: e.clientX - zoomImageState.position.x,
        y: e.clientY - zoomImageState.position.y,
      },
    });
  };

  const handleMouseMove = (e) => {
    if (zoomImageState.isDragging) {
      updateZoomImageState({
        position: {
          x: e.clientX - zoomImageState.startPosition.x,
          y: e.clientY - zoomImageState.startPosition.y,
        },
      });
    }
  };

  const handleMouseUp = () => {
    updateZoomImageState({ isDragging: false });
  };

  const handleWheel = (e) => {
    e.preventDefault();
    const rect = genImageRef.current?.getBoundingClientRect();
    if (!rect) return;

    // Calculate mouse position relative to image
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    // Calculate zoom
    const delta = e.deltaY > 0 ? 0.9 : 1.1;
    const newZoom = Math.max(0.5, Math.min(3, zoomImageState.zoom * delta));

    // Calculate new position to zoom towards mouse
    const newPosition = {
      x: zoomImageState.position.x - mouseX * (delta - 1),
      y: zoomImageState.position.y - mouseY * (delta - 1),
    };

    updateZoomImageState({ zoom: newZoom, position: newPosition });
  };

  const handleClose = () => {
    updateZoomImageState({
      isVisible: false,
      zoom: 1,
      position: { x: 0, y: 0 },
      selectedImage: undefined,
    });
  };
  useEffect(() => {
    getImageData(id === "editor" ? "fashion" : id);
  }, []);

  const downloadImage = (imageUrl, fileName = "download-image.png") => {
    // Remove base64 data URL prefix if present
    const base64Data = imageUrl.split(",")[1] || imageUrl;

    // Convert base64 to blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });

    // Create download link
    const downloadUrl = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = downloadUrl;
    downloadLink.download = fileName;

    // Trigger download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Cleanup
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(downloadUrl);
  };

  const uploadImage = async (image) => {
    setUploadLoader(true);
    const fileNames = [image.fileName];
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_background_generate",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        selected_image_ids: fileNames,
      },
    };

    try {
      let response = await axios(config);
      // console.log("response", response);
      if (response != undefined) {
        setUploadLoader(false);
        alert("Image saved to backdrop Images");
      }
    } catch (error) {
      console.log("data error", error);
      setUploadLoader(false);
      alert("Failed to save image please try again.");
    }
  };
  return (
    <>
      {overlayGeneratedState.visible ? (
        <CCol
          style={{ overflow: "scroll" }}
          className="ps-1 mt-3 pb-3 position-relative h-100"
        >
          <CButton
            style={{ fontSize: "12px" }}
            className="custom-btn d-flex align-items-center gap-2 px-2 py-1 m-0 mb-2 ms-3"
            onClick={() => {
              updateGeneratorState({
                visible: false,
                generatedImages: [],
                loading: false,
              });
              setOverlayEditorVisible(false);
              updateFormState({
                prompt: "",
                numberOfImages: 1,
                selectedImage: null,
              });
              updateGeneratePromptState({
                prompt: [],
                loading: false,
              });
            }}
          >
            <IoArrowBackSharp /> Generate More
          </CButton>
          <CCard
            className="mx-auto p-2"
            style={{
              width: "95%",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}
          >
            {overlayGeneratedState.loading ? (
              <div className="row g-2">
                {[...Array(Number(formState.numberOfImages) || 4)].map(
                  (_, index) => (
                    <div key={index} className="col-md-6">
                      <div
                        className="position-relative"
                        style={{ paddingBottom: "100%" }}
                      >
                        <Skeleton
                          className="position-absolute top-0 start-0 w-100 h-100 rounded"
                          style={{ minHeight: "200px" }}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : overlayGeneratedState.generatedImages.length === 0 ? (
              <CCol>Image not generated try again!</CCol>
            ) : (
              <CCol>
                <CCol
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "GrayText",
                    borderBottom: "1px solid lightgray",
                  }}
                >
                  Generated Images
                </CCol>
                <div className="row g-2 mt-2">
                  {overlayGeneratedState.generatedImages.map((image, index) => (
                    <div key={index} className="col-md-6">
                      <div
                        className="position-relative rounded overflow-hidden"
                        style={{
                          boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                          paddingBottom: "100%",
                        }}
                      >
                        <img
                          src={image.url}
                          alt={`Gallery item ${index + 1}`}
                          className="position-absolute top-0 start-0 w-100 h-100"
                          style={{
                            objectFit: "contain",
                            transition: "transform 0.2s",
                            "&:hover": {
                              transform: "scale(1.05)",
                            },
                          }}
                          onClick={() =>
                            updateZoomImageState({
                              isVisible: true,
                              selectedImage: image,
                            })
                          }
                        />
                        <CCol
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            zIndex: 1,
                          }}
                          className="d-flex align-items-center gap-2"
                        >
                          <CTooltip
                            content="Download Image"
                            placement="top"
                            trigger={["hover", "focus"]}
                          >
                            <div>
                              <IoMdCloudDownload
                                onClick={() => downloadImage(image.url)}
                                size={20}
                                color="rgb(139,123,192)"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </CTooltip>
                          <CTooltip
                            content="Save to Backdrop Image"
                            placement="top"
                            trigger={["hover", "focus"]}
                          >
                            <div className="d-flex align-items-center ">
                              {uploadLoader ? (
                                <CSpinner
                                  style={{
                                    width: "15px",
                                    color: "rgb(139,123,192)",
                                    height: "15px",
                                  }}
                                />
                              ) : (
                                <FaSave
                                  onClick={() => uploadImage(image)}
                                  size={17}
                                  color="rgb(139,123,192)"
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </div>
                          </CTooltip>
                        </CCol>
                      </div>
                    </div>
                  ))}
                </div>
              </CCol>
            )}
          </CCard>
        </CCol>
      ) : (
        <CCol
          style={{ overflow: "scroll" }}
          className="ps-1 pb-3 position-relative h-100"
        >
          <div>
            <div className="container-sm bg-white rounded p-4 position-relative">
              <div className="text-center">
                <h6 className="mt-3 fw-bold text-secondary">Upload Image</h6>
              </div>
              <form
                className="mt-2  mx-auto d-flex flex-column align-items-center justify-content-center"
                style={{ width: "40%" }}
              >
                <div className="mb-1 w-100">
                  <div
                    className=" rounded text-center d-flex flex-column align-items-center justify-content-center"
                    style={{
                      borderStyle: "dashed",
                      borderColor: "rgba(139, 123, 192, 0.64)",
                      cursor: "pointer",
                      height: "150px",
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={handleAreaClick}
                  >
                    {formState.selectedImage ? (
                      <img
                        src={formState.selectedImage}
                        alt="Selected"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <>
                        <div className="mb-2">
                          <FaFileImage color="rgb(139,123,192)" size={50} />
                        </div>
                        <p className="text-secondary">
                          <span className="fw-bold">Drag and drop</span> files
                          here <br /> or{" "}
                          <a
                            href="#!"
                            className="text-primary text-decoration-underline"
                          >
                            select a file
                          </a>{" "}
                          from your computer
                        </p>
                      </>
                    )}
                    <input
                      type="file"
                      className="form-control d-none"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <p className="text-muted w-100 mb-0">
                  <small>File type: png</small>
                </p>
                {formState.selectedImage && (
                  <CAlert color="success" className="px-4 py-1 mt-2">
                    Click on generate to generate background
                  </CAlert>
                )}

                {/* <div className="w-100 d-flex align-items-center gap-2">
              <CButton
                color="light"
                className=" w-100 py-1 fw-semibold shadow-sm  d-flex align-items-center gap-2 justify-content-center"
              >
                <RiImageEditFill size={20} />
                Edit
              </CButton>
              <CButton
                variant="outline"
                type="submit"
                className="w-100 custom-btn fw-semibold  shadow-sm justify-content-center  d-flex align-items-center gap-2"
              >
                <MdCloudUpload size={20} /> Upload
              </CButton>
            </div> */}
              </form>
            </div>
          </div>
          <div className="text-center">
            <h6 className="mt-1 fw-bold text-secondary">
              Select Image from cloud storage
            </h6>
          </div>
          <CTabs activeItemKey={1}>
            <CTabList
              variant="underline-border text-secondary "
              style={{
                fontSize: "14px",
              }}
            >
              {tabs.map((item, index) => (
                <CTab
                  className="tab-link"
                  itemKey={index + 1}
                  key={item.id}
                  onClick={() => getImageData(item.id)}
                >
                  {item.name}
                </CTab>
              ))}
            </CTabList>
            <CTabContent>
              {tabs.map((item, index) => (
                <CTabPanel
                  className="p-3"
                  visible={true}
                  itemKey={index + 1}
                  key={index}
                >
                  <CCol className="w-100 position-relative">
                    <ImageGalleryGrid
                      images={imageDataState.imageData}
                      loading={imageDataState.loading}
                      handleEdit={handleEdit}
                    />
                  </CCol>
                </CTabPanel>
              ))}
            </CTabContent>
          </CTabs>
        </CCol>
      )}

      {zoomImageState.isVisible && (
        <div
          className="img-enlargable-container"
          style={{
            cursor: zoomImageState.isDragging ? "grabbing" : "grab",
          }}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="zoom-btn-container">
            <RiZoomOutFill onClick={handleZoomOut} className="zoom-btn" />

            <RiZoomInFill onClick={handleZoomIn} className="zoom-btn" />
          </div>
          <div className="close-btn-container">
            <CTooltip content="Download Image">
              <button
                onClick={() => downloadImage(zoomImageState.selectedImage.url)}
                className="close-btn"
              >
                <IoMdCloudDownload size={30} />
              </button>
            </CTooltip>
            <CTooltip content="Save to Backdrop Image">
              <button
                onClick={() => uploadImage(zoomImageState.selectedImage)}
                className="close-btn"
              >
                {uploadLoader ? (
                  <CSpinner
                    style={{
                      width: "30px",
                      color: "rgb(139,123,192)",
                      height: "30px",
                    }}
                  />
                ) : (
                  <FaSave size={30} />
                )}
              </button>
            </CTooltip>

            <button onClick={handleClose} className="close-btn">
              <IoClose size={30} />
            </button>
          </div>
          {zoomImageState.selectedImage && (
            <div
              ref={genImageRef}
              className={"zoom-img-container"}
              style={{
                transform: `translate(${zoomImageState.position.x}px, ${zoomImageState.position.y}px) scale(${zoomImageState.zoom})`,
                transition: zoomImageState.isDragging
                  ? "none"
                  : "transform 0.2s",
                cursor: zoomImageState.isDragging ? "grabbing" : "grab",
              }}
              onMouseDown={handleMouseDown}
              onWheel={handleWheel}
            >
              <img
                src={zoomImageState.selectedImage.url}
                alt="Zoomed"
                className="zoom-img"
                draggable={false}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default OverlayChat;
const ImageGalleryGrid = ({ images, loading, handleEdit }) => {
  if (loading) {
    return (
      <div className="row g-4">
        {[...Array(8)].map((_, index) => (
          <div key={index} className="col-md-3">
            <div
              className="position-relative"
              style={{ paddingBottom: "100%" }}
            >
              <Skeleton
                className="position-absolute top-0 start-0 w-100 h-100 rounded"
                style={{ minHeight: "200px" }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="row g-4">
      {images.map((image, index) => (
        <div key={index} className="col-md-3">
          <div
            className="position-relative rounded overflow-hidden"
            style={{ paddingBottom: "100%" }}
          >
            <img
              src={image.url}
              alt={`Gallery item ${index + 1}`}
              className="position-absolute top-0 start-0 w-100 h-100"
              style={{
                objectFit: "contain",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => {
                handleEdit(image.url);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
