import {
  CButton,
  CCol,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CTooltip,
} from "@coreui/react";
import { useState } from "react";
import { TiZoomIn, TiZoomOut } from "react-icons/ti";
import { BiUndo, BiRedo } from "react-icons/bi";

const EditorFooter = (props) => {
  const [state, setState] = useState({});
  const updateState = (updates) =>
    setState((prev) => ({ ...prev, ...updates }));
  return (
    <CCol
      className="w-100 d-flex align-items-center justify-content-between"
      style={{ maxHeight: "40px", background: "white" }}
    >
      <div
        className="d-flex align-items-center gap-1 px-2 ms-2"
        style={{ background: "rgba(149, 126, 216, 0.2)", borderRadius: "5px" }}
      >
        <CTooltip
          className="custom-tooltip"
          placement="top"
          content={
            <div>
              Undo <br /> Ctrl+Z
            </div>
          }
        >
          <div>
            <BiUndo
              style={{
                width: "27px",
                height: "27px",
                color:
                  props.currentStep <= 0
                    ? "rgb(202, 189, 244)"
                    : "rgb(128, 106, 198)",
                cursor: "pointer",
              }}
              onClick={() => !(props.currentStep <= 0) && props.handleUndo()}
            />
          </div>
        </CTooltip>

        <CTooltip
          className="custom-tooltip"
          placement="top"
          content={
            <div>
              Redo <br /> Ctrl+Shift+Z
            </div>
          }
        >
          <div className="ms-1">
            <BiRedo
              style={{
                width: "27px",
                height: "27px",
                color:
                  props.currentStep >= props.history.length - 1
                    ? "rgb(202, 189, 244)"
                    : "rgb(128, 106, 198)",
                cursor: "pointer",
              }}
              onClick={() =>
                !(props.currentStep >= props.history.length - 1) &&
                props.handleRedo()
              }
            />
          </div>
        </CTooltip>
      </div>
      <div
        className="d-flex align-items-center gap-1 px-2 me-2"
        style={{ background: "rgba(149, 126, 216, 0.2)", borderRadius: "5px" }}
      >
        <CTooltip
          className="custom-tooltip"
          placement="top"
          content={"Zoom Out"}
          onClick={() => props.handleManualZoom(props.scale - 0.1)}
        >
          <div>
            <TiZoomOut
              style={{
                width: "23px",
                height: "23px",
                color: "rgb(128, 106, 198)",
                cursor: "pointer",
              }}
              onClick={() => props.handleManualZoom(props.scale - 0.1)}
            />
          </div>
        </CTooltip>

        <input
          type="range"
          className="form-range zoom-range"
          style={{ width: "150px" }}
          min="10"
          max="300"
          value={props.zoomLevel}
          onChange={props.handleZoomInput}
        />
        <CTooltip
          className="custom-tooltip"
          placement="top"
          content={"Zoom In"}
        >
          <div>
            <TiZoomIn
              style={{
                width: "23px",
                height: "23px",
                color: "rgb(128, 106, 198)",
                cursor: "pointer",
              }}
              onClick={() => props.handleManualZoom(props.scale + 0.1)}
            />
          </div>
        </CTooltip>
        <CButton
          color="light"
          variant="outline"
          className="px-2 py-1"
          style={{
            fontSize: "13px",
            color: "rgb(36, 36, 36)",
            fontWeight: "500",
            width: "50px",
          }}
          //   onClick={() => updateState(!state.zoomBtnVis)}
        >
          {props.zoomLevel}%
        </CButton>
      </div>
    </CCol>
  );
};
export default EditorFooter;
