import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormTextarea,
  CSpinner,
  CTab,
  CTabContent,
  CTabList,
  CTabPanel,
  CTabs,
} from "@coreui/react";
import React, { useState } from "react";
import axios from "axios";
import {
  TbPencilQuestion,
  TbArrowAutofitHeight,
  TbArrowAutofitWidth,
} from "react-icons/tb";
import { useParams } from "react-router-dom";

const OverlayImage = ({
  formState,
  updateFormState,
  resizeVisible,
  resizeState,
  updateResizeState,
  handleExportForGeneration,
  overlayGeneratedState,
  updateGeneratorState,
  generatedPromptState,
  updateGeneratePromptState,
}) => {
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState("generateOverlay");

  const BASE_WIDTH = 1920;
  const BASE_HEIGHT = 1080;

  const handleGenerate = async (e) => {
    updateGeneratorState({
      visible: true,
      generatedImages: [],
      loading: true,
    });
    if (!formState.prompt) {
      setResponseMessage("Please provide a prompt and select an image.");
      return;
    }

    const formData = new FormData();
    if (selectedTab === "generateOverlay") {
      formData.append("prompt", formState.prompt);
    } else {
      formData.append("subject_color", formState.prompt);
    }
    formData.append("number_of_images", formState.numberOfImages);
    if (resizeVisible) {
      const imageFile = await handleExportForGeneration();
      console.log("imagefile", imageFile);
      formData.append("image_file", imageFile);
    } else {
      const base64Response = await fetch(formState.selectedImage);
      const blob = await base64Response.blob();
      const imageFile = new File([blob], "selected_image.png", {
        type: "image/png",
      });
      formData.append("image_file", imageFile);
    }
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_background_generate",

      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      data: formData,
    };
    try {
      setResponseMessage("Generating background...");
      const response = await axios(config);
      console.log("response", response);
      setResponseMessage("Background generated successfully!");
      signedUrl(); // Adjust according to API response
    } catch (error) {
      console.error("Error generating background:", error);
      setResponseMessage("Failed to generate the background.");
      updateGeneratorState({
        generatedImages: [],
        loading: false,
      });
    }
  };
  async function convertUrlsToBase64(inputArray) {
    const fetchBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    };

    const convertedArray = await Promise.all(
      inputArray.map(async (item) => {
        const base64Url = await fetchBase64(item.url);
        return {
          ...item,
          url: base64Url,
        };
      })
    );

    return convertedArray;
  }
  const signedUrl = async () => {
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_signed_url",

      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        bucket_name: "croma_images",
        folder_name: "temp_images2",
      },
    };
    try {
      setResponseMessage("Generating background...");
      const response = await axios(config);
      console.log("response", response);
      setResponseMessage("Background generated successfully!");
      let base64Arr = await convertUrlsToBase64(response.data.signed_urls);
      updateGeneratorState({
        generatedImages: base64Arr || [],
        loading: false,
      });
      // Adjust according to API response
    } catch (error) {
      console.error("Error generating background:", error);
      updateGeneratorState({
        generatedImages: [],
        loading: false,
      });
      setResponseMessage("Failed to generate the background.");
    }
  };

  const generatePrompt = async () => {
    updateGeneratePromptState({
      prompt: [],
      loading: true,
    });

    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_image_generation",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        prompt: formState.prompt,
      },
    };
    try {
      const response = await axios(config);
      updateGeneratePromptState({
        prompt: response.data.prompts,
        loading: false,
      });
    } catch (error) {
      console.error("Error generating background:", error);
      updateGeneratePromptState({
        loading: false,
      });
    }
  };

  const handleWidthChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      updateResizeState({
        value: { ...resizeState.value, width: value },
      });
    }
  };

  const handleHeightChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      updateResizeState({
        value: { ...resizeState.value, height: value },
      });
    }
  };
  const convertToNewUnit = (currentValue, oldUnit, newUnit, baseValue) => {
    if (oldUnit === newUnit) return currentValue;

    if (oldUnit === "pixel" && newUnit === "percentage") {
      return Math.round((currentValue / baseValue) * 100);
    } else if (oldUnit === "percentage" && newUnit === "pixel") {
      return Math.round((currentValue * baseValue) / 100);
    }
    return currentValue;
  };

  const unitOnchange = (e) => {
    const newUnit = e.target.name;
    const currentUnit = resizeState.unit;
    const newWidth = convertToNewUnit(
      resizeState.value.width,
      currentUnit,
      newUnit,
      BASE_WIDTH
    );

    const newHeight = convertToNewUnit(
      resizeState.value.height,
      currentUnit,
      newUnit,
      BASE_HEIGHT
    );
    updateResizeState({
      unit: newUnit,
      value: {
        width: newWidth,
        height: newHeight,
      },
    });
  };

  return (
    <>
      <CTabs
        activeItemKey={1}
        onChange={(index) =>
          index === 1
            ? setSelectedTab("generateOverlay")
            : setSelectedTab("changeColor")
        }
      >
        <CTabList variant="underline-border" className="w-100">
          <CTab
            aria-controls="home-tab-pane"
            className="tab-link-genrator "
            itemKey={1}
          >
            Generate Overlay
          </CTab>
          <CTab
            aria-controls="profile-tab-pane"
            className="tab-link-genrator"
            itemKey={2}
          >
            Change Color
          </CTab>
        </CTabList>
      </CTabs>
      <CCol
        className="w-100 position-relative px-2 h-100"
        style={{ overflow: "scroll", paddingBottom: "100px" }}
      >
        <CForm className="pt-3">
          <CCard
            style={{
              backgroundColor: "transparent",
              border: "none",
              width: "100%",
              padding: "2px",
              borderRadius: "50px",
            }}
          >
            <CCol
              className="d-flex flex-column w-100 inputfocus  "
              style={{
                backgroundColor: "white",
                border: "none",
              }}
            >
              <CFormTextarea
                className=" inputfocus form-control font-md"
                style={{
                  backgroundColor: "white",
                  boxShadow: "none",
                  color: "#086EB5",
                  borderRadius: "5px",
                }}
                rows={4}
                onChange={(e) => {
                  e.preventDefault();
                  updateFormState({ prompt: e.target.value });
                }}
                value={formState.prompt}
                placeholder={
                  selectedTab
                    ? "Enter a prompt for the overlay generation"
                    : "Enter a prompt for the changing color"
                }
                // disabled={props.loading}
              />
            </CCol>
            <CCol
              style={{
                color:
                  formState.prompt == ""
                    ? "rgb(108, 159, 236)"
                    : "rgb(13,110,253)",
                cursor: "pointer",
              }}
              className="d-flex align-items-center justify-content-end gap-1 p-2"
              onClick={() => formState.prompt !== "" && generatePrompt()}
            >
              {generatedPromptState.loading ? (
                <CSpinner style={{ width: "16px", height: "16px" }} />
              ) : (
                <TbPencilQuestion size={16} />
              )}
              Assist with prompt
            </CCol>
            {generatedPromptState.prompt &&
              generatedPromptState.prompt.length > 0 && (
                <CCol className="d-flex flex-column gap-2 mb-2">
                  {generatedPromptState.prompt &&
                    generatedPromptState.prompt.map((item) => (
                      <CCard
                        className={`p-2 prompt-card ${
                          formState.prompt == item.enhanced_prompt &&
                          "prompt-card-active"
                        }`}
                        onClick={() =>
                          updateFormState({ prompt: item.enhanced_prompt })
                        }
                      >
                        {item.enhanced_prompt}
                      </CCard>
                    ))}
                </CCol>
              )}
          </CCard>
          <CCol className="mb-3">
            <div className="d-flex  align-items-center flex-wrap ">
              <span style={{ fontSize: "13px" }} className="me-2">
                Number of Images:
              </span>
              <select
                style={{ width: "60px", fontSize: "12px" }}
                className="form-select p-0 ps-2"
                aria-label="Disabled select example"
                onChange={(e) =>
                  updateFormState({ numberOfImages: e.target.value })
                }
                value={formState.numberOfImages}
              >
                {Array.from({ length: 4 }, (_, i) => (
                  <option value={i + 1}>{i + 1}</option>
                ))}
              </select>
            </div>
          </CCol>
          <CButton
            color="success"
            size="sm"
            className=" d-flex align-items-center justify-content-center gap-2 p-0 py-1  hover w-100"
            onClick={handleGenerate}
            disabled={overlayGeneratedState.loading || formState.prompt === ""}
          >
            {overlayGeneratedState.loading && <CSpinner size="sm" />}
            {selectedTab === "generateOverlay"
              ? "Generate Overlay"
              : "Change Color"}
          </CButton>
        </CForm>
        {resizeVisible && (
          <CCard
            className="m-0 mt-3 p-2"
            style={{ backgroundColor: "rgba(241, 241, 241, 0.26)" }}
          >
            <CCol
              className="m-0 p-0"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                borderBottom: "1px solid rgb(229, 229, 229)",
              }}
            >
              Resize
            </CCol>
            <div className={"d-flex align-items-top gap-3 mt-2"}>
              By:
              <div className={"d-flex align-items-top gap-2"}>
                <CFormCheck
                  type="radio"
                  name="percentage"
                  id="percentage"
                  label="Percentage"
                  checked={resizeState.unit === "percentage"}
                  onChange={unitOnchange}
                />
                <CFormCheck
                  type="radio"
                  name="pixel"
                  checked={resizeState.unit === "pixel"}
                  id="pixel"
                  label="Pixel"
                  onChange={unitOnchange}
                  defaultChecked
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-3 mt-2">
              <div className="d-flex align-items-center gap-2">
                <TbArrowAutofitWidth size={25} color="rgb(139,123,192)" />{" "}
                <div
                  style={{ minWidth: "60px", fontWeight: "500" }}
                  className="text-sm"
                >
                  Horizontal:
                </div>
                <CFormInput
                  type="number"
                  className="py-1 px-2 font-size-input"
                  value={resizeState.value.width}
                  onChange={handleWidthChange}
                  style={{ width: "60px", fontSize: "14px" }}
                  min="1"
                />
              </div>

              <div className="d-flex align-items-center gap-2">
                <TbArrowAutofitHeight size={25} color="rgb(139,123,192)" />
                <div
                  style={{ minWidth: "60px", fontWeight: "500" }}
                  className="text-sm"
                >
                  Vertical:
                </div>
                <CFormInput
                  type="number"
                  className="py-1 px-2 font-size-input"
                  value={resizeState.value.height}
                  onChange={handleHeightChange}
                  style={{
                    width: "60px",
                    fontSize: "14px",
                  }}
                  min="1"
                />
              </div>
            </div>
          </CCard>
        )}
      </CCol>
    </>
  );
};

export default OverlayImage;
