import { CAlert, CCol, CTab, CTabList, CTabs } from "@coreui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ImageGallery from "./ImageGallary";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";

const OtherImageGallary = ({
  type,
  elements,
  updateElements,
  selectedElement,
  setSelectedElement,
}) => {
  const { id } = useParams();
  const [imageData, setImageData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState("generatedImages");

  async function imageUrlToBase64(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Base64:", error);
    }
  }

  const handleAddImage = (imageUrl) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // To handle cross-origin requests if necessary

    imageUrlToBase64(imageUrl)
      .then((base64) => {
        img.src = base64;

        img.onload = () => {
          // Ensure image is loaded before calculating width and height
          const newImage = {
            src: img.src,
            id: uuidv4(),
            x: 50,
            y: 50,
            width: img.naturalWidth, // Using naturalWidth and naturalHeight
            height: img.naturalHeight,
          };
          updateElements({ images: [...elements.images, newImage] });
        };

        img.onerror = () => {
          console.error("Failed to load image:", imageUrl);
        };
      })
      .catch((error) => console.error("Error:", error));
  };
  useEffect(() => {
    getImageData(type);
  }, [type, selectedTab]);
  const getImageData = async (type) => {
    setImageData([]);
    setLoader(true);
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_signed_url",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        bucket_name: "croma_images",
        folder_name:
          type === "backdrop_images" && selectedTab === "generatedImages"
            ? "backdrop_images/generated_images"
            : type === "backdrop_images" && selectedTab === "alteredImages"
            ? "backdrop_images/altered_images"
            : type === "logo_images" && id === "fashion"
            ? "logo_images/fashion_logo"
            : type === "logo_images" && id === "furniture"
            ? "logo_images/furniture_logo"
            : type,
      },
    };

    try {
      // let response = tempResp;
      let response = await axios(config);
      console.log("response get", response);
      if (response != undefined) {
        let base64Arr = await convertUrlsToBase64(response.data.signed_urls);
        setImageData(base64Arr);
        setLoader(false);
      }
    } catch (error) {
      console.log("data error", error);
      setLoader(false);
    }
  };
  async function convertUrlsToBase64(inputArray) {
    const fetchBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    };

    const convertedArray = await Promise.all(
      inputArray.map(async (item) => {
        const base64Url = await fetchBase64(item.url);
        return {
          ...item,
          url: base64Url,
        };
      })
    );

    return convertedArray;
  }

  return (
    <>
      {type === "backdrop_images" && (
        <CTabs
          activeItemKey={1}
          onChange={(index) =>
            index === 1
              ? setSelectedTab("generatedImages")
              : setSelectedTab("alteredImages")
          }
        >
          <CTabList variant="underline-border" className="w-100">
            <CTab
              aria-controls="home-tab-pane"
              className="tab-link-genrator "
              itemKey={1}
            >
              Generated Images
            </CTab>
            <CTab
              aria-controls="profile-tab-pane"
              className="tab-link-genrator"
              itemKey={2}
            >
              Altered Images
            </CTab>
          </CTabList>
        </CTabs>
      )}
      <CCol
        className="w-100 position-relative h-100"
        style={{ overflow: "scroll", paddingBottom: "50px" }}
      >
        {loader ? (
          <div className="gallery p-2">
            <div className="galleryItem d-flex flex-column position-relative">
              <CCol>
                <SkeletonTheme
                  baseColor="rgb(240,241,245)"
                  highlightColor="rgb(251, 251, 251)"
                >
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            </div>
            <div className="galleryItem d-flex flex-column position-relative">
              <CCol>
                <SkeletonTheme
                  baseColor="rgb(240,241,245)"
                  highlightColor="rgb(251, 251, 251)"
                >
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            </div>
            <div className="galleryItem d-flex flex-column position-relative">
              <CCol>
                <SkeletonTheme
                  baseColor="rgb(240,241,245)"
                  highlightColor="rgb(251, 251, 251)"
                >
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            </div>
          </div>
        ) : imageData.length == 0 ? (
          <CAlert className="px-2 py-1 m-2 mt-3">
            No{" "}
            {type == "backdrop_images"
              ? "Backdrop Images"
              : type == "fashion"
              ? "Product Images"
              : type == "logo_images"
              ? "Logo Images"
              : type == "furniture"
              ? "Stickers"
              : "Text"}{" "}
            available.
          </CAlert>
        ) : (
          <ImageGallery
            id={"otherImages"}
            selectedTab={selectedTab}
            images={imageData}
            getImageData={getImageData}
            setImages={setImageData}
            // setBackgroundImage={props.setBackgroundImage}
            updateElements={updateElements}
            handleAddImage={handleAddImage}
            type={type}
          />
        )}
      </CCol>
    </>
  );
};

export default OtherImageGallary;
